.answers-detail{
	padding: 60px;
	@media (max-width: $lg-width){
		padding:60px 30px;
	}
	@media (max-width: $md-width){
		padding:30px;
	}
	.mobile-toggle-menu{
		display: none;
		margin-bottom: 30px;
		@media (max-width: $md-width){
			display: block;
		}
		a{
			font-family: $poppinsregular;
			@include font-size(15,20);
			color:$lightblack;
			padding: 15px 65px 15px 22px;
			border:1px solid #dfdfdf;
			position: relative;
			border-radius: 5px;
			position: relative;
			display: block;
			&:before{
				content:"\e90d";
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translate(0,-50%) rotate(90deg);
				font-family: 'icomoon';
			}
		}
	}
	.accordion-menu{
		@media (max-width: $md-width){
			display: none;
			margin-bottom: 30px;
		}
		#accordion-wrapper{
			> .card{
				border-radius: 5px;
				+ .card{
					margin-top: 5px;
				}
				> .card-header{
					padding: 0;
					background: none;
					border:none;
					> .card-link{
						font-family: $poppinsregular;
						@include font-size(13,16);
						color:$lightblack;
						display: block;
						background: $white;
						padding: 15px 15px 15px 20px;
						border-radius: 5px;
						position: relative;
						&:before{
							content:"";
							position: absolute;
							left: -1px;
							top: 0;
							width: 3px;
							height: 100%;
							background: $darkblue;
							opacity: 0;
							border-radius: 5px 0 0 5px;
						}
					}
				}
				> .first-collapse{
					> .card-body{
						border-top: 1px solid rgba(0,0,0,.125);
						padding: 0;
						.card{
							border:none;
							.card-header{
								padding: 0;
								.card-link{
									font-family: $poppinsregular;
									@include font-size(13,16);
									color:$lightblack;
									display: block;
									background: #f2f5ff;
									padding: 15px 35px 15px 20px;
									position: relative;
									i{
										&.icon-right-arrow{
											font-size: 14px;
											position: absolute;
											right: 15px;
											top: 50%;
											transform: translate(0,-50%);
											color:#5a5a5a;
											font-weight: bold;
										}
									}
								}
							}
							.card-body{
								padding: 0;
								.card{
									.card-header{
										.card-link{
											background: #fafdfd;
											i{
												font-size: 12px;
												position: absolute;
												right: 15px;
												top: 50%;
												transform: translate(0,-50%);
												color:#5a5a5a;
											}
										}
									}
									&.active{
										.card-header{
											.card-link{
												i{
													color:$darkblue;
												}
											}
										}
									}
								}
							}
							&.active{
								.card-header{
									.card-link{
										i{
											&.icon-right-arrow{
												transform: translate(0,-50%) rotate(90deg);
												color:$darkblue;
											}
										}
									}
								}
							}
						}
						> ul{
							li{
								&:last-child{
									border-bottom:none;
								}
							}
						}
					}
				}
				&.active{
					> .card-header{
						> .card-link{
							&:before{
								opacity:1;
							}
						}
					}
				}
				ul{
					li{
						border-bottom: 1px solid rgba(0,0,0,.125);
						a{
							font-family: $poppinsregular;
							@include font-size(13,18);
							color:$lightblack;
							padding: 15px 15px 15px 37px;
							position: relative;
							background: #f3f9fb;
							display: block;
							&:before{
								content:"";
								position: absolute;
								left: 25px;
								top: 50%;
								transform: translate(0,-50%);
								width: 5px;
								height: 5px;
								border-radius: 50%;
								background: $darkblue;
							}
						}
					}
				}
			}
		}
	}
	&__content{
		&_product{
			border:1px solid #dfdfdf;
			border-radius: 5px;
			margin-bottom: 30px;
			figure{
				height: 240px;
				width: 100%;
				padding: 35px 15px;
				text-align: center;
				img{
					max-width: 100%;
					max-height: 180px;
				}
			}
			ol{
				&.carousel-indicators{
					position: absolute;
					left: 0;
					bottom: 10px;
					width: 100%;
					transform: translate(0,0);
					margin:0;
					li{
						background-color:$blue;
					}
				}
			}
		}
		a{
			&.offer-button-add{
				font-family: $poppinsregular;
				@include font-size(15,20);
				color:$white;
				background: $darkblue;
				padding: 10px 25px;
				border-radius: 5px;
				display: inline-block;
				margin:0 0 20px 0;
			}
		}
		h2{
			font-family: $poppinsmedium;
			@include font-size(19,26);
			color:$lightblack;
			margin-bottom: 14px;
		}
		p{
			font-family: $poppinslight;
			@include font-size(14,20);
			color:$lightblack;
			margin-bottom: 25px;
		}
		> ul{
			margin:30px;
			li{
				font-family: $poppinslight;
				@include font-size(13,19);
				color:$lightblack;
				margin-bottom: 5px;
				position: relative;
				&:before{
					content:"";
					position: absolute;
					left: -10px;
					top: 50%;
					transform: translate(0,-50%);
					width: 4px;
					height: 4px;
					background: $lightblack;
					border-radius: 50%;
				}
			}
		}
		&_title{
			margin-bottom: 14px;
			h3{
				font-family: $poppinsregular;
				@include font-size(15,20);
				color:$lightblack;
				padding: 15px 22px;
				border:1px solid #dfdfdf;
				position: relative;
				border-radius: 5px;
				&:before{
					content:"";
					position: absolute;
					left: 0;
					top: 0;
					width: 3px;
					height: 100%;
					background:$darkblue;
					border-radius: 5px 0 0 5px;
				} 
			}
		}
		&_pdflist{
			ul{
				li{
					&:not(:last-child){
						margin-bottom: 7px;
					}
					a{
						font-family: $poppinsregular;
						@include font-size(15,20);
						color:$lightblack;
						padding: 15px 65px 15px 22px;
						border:1px solid #dfdfdf;
						position: relative;
						border-radius: 5px;
						position: relative;
						display: block;
						span{
							display: inline-block;
							position: absolute;
							right: 0;
							top: 0;
							height: 100%;
							width: 50px;
							border-left: 1px solid #dfdfdf;
							display: flex;
							align-items: center;
							justify-content: center;
							i{
								font-size: 19px;
								color:$darkblue;
							}
						}
					}
				}
			}
		}
	}
}