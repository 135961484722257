.products_list{
	.row{
		> div{
			margin-bottom: 30px;
		}
	}
	&__banner{
		margin-bottom: 20px;
		figure{
			height: 250px;
			width: 100%;
		}
	}
	&__categorie_content{
		h1{
			font-family: $poppinsmedium;
			@include font-size(20,26);
			color:$lightblack;
			margin-bottom: 14px;
		}
		p{
			font-family: $poppinslight;
			@include font-size(14,20);
			color:$lightblack;
			margin-bottom: 25px;
		}
	}
	&__wrap{
		height: 100%;
		a{
			display: block;
			padding: 25px 20px;
			border:1px solid #dfdfdf;
			border-radius: 5px;
			height: 100%;
			@include transition;
			&:hover{
				border-color:$blue;
			}
			figure{
				height: 200px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 15px;
				img{
					max-width: 180px;
					max-height: 180px;
				}
			}
			h3{
				font-family: $poppinsmedium;
				@include font-size(16,20);
				color:$lightblack;
				text-align: center;
			}
		}
	}
}