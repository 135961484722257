.projects{
	padding: 74px 70px;
	@media (max-width: $md-width){
		padding: 40px 30px;
	}
	@media (max-width: $sm-width){
		padding: 30px 15px;
	}
	.row{
		> div{
			margin-bottom: 30px;
		}
	}
	&__wrap{
		background: $white;
		border:1px solid #d9d9d9;
		border-radius: 5px;
		height: 100%;
		figure{
			height: 240px;
			width: 100%;
			border-radius: 5px 5px 0 0;
			position: relative;
			&:before{
				content:"";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: $darkblue;
				opacity: 0;
				@include transition;
			}
			span{
				display: inline-block;
				width: 47px;
				height: 47px;
				background: $blue;
				position: absolute;
				right: 22px;
				top: 22px;
				border-radius: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				i{
					font-size: 22px;
					color:$white;
				}
			}
		}
		section{
			padding: 24px;
			h3{
				font-family: $poppinsmedium;
				@include font-size(17,24);
				color:$lightblack;
				margin-bottom: 16px;
			}
			p{
				font-family: $poppinslight;
				@include font-size(12,16);
				color:$lightblack;
				i{
					font-size: 16px;
					margin-right: 8px;
					color:$darkblue;
				}
			}
		}
		&:hover{
			figure{
				&:before{
					opacity:0.5;
				}
			}
		}
	}
}