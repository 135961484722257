.technical-service{
	background:$white url(#{$images}/content/technical-service-banner.jpg) no-repeat center left;
	background-size: auto 100%;
	@media (max-width: $md-width){
		background-size: auto 560px;
		background-position: top center;
	}
	@media (max-width: $sm-width){
		background-size: auto 400px;
	}
	section{
		padding:60px 40px 60px 30px;
		@media (max-width: $md-width){
			padding-top: 500px;
			padding: 40px;
		}
		@media (max-width: $sm-width){
			padding-top: 400px;
		}
		ul li,p{
			font-family: $poppinslight;
			@include font-size(14,20);
			color:$lightblack;
			margin-bottom: 22px;
		}
		h2{
			font-family: $poppinsbold;
			@include font-size(18,27);
			color:$blue;
			margin-bottom: 20px;
		}
		ul{
			&:not(.actions){
				margin-bottom: 35px;
				li{
					margin-bottom: 15px;
					position: relative;
					padding-left: 15px;
					&:before{
						content:"";
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 5px 0 5px 7px;
						border-color: transparent transparent transparent #d6d6d6;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(0,-50%);
					}
				}
			}
			&.actions{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding-top: 40px;
				@media (max-width: $md-width){
					flex-direction:column;
					align-items: flex-start;
					padding-top: 20px;
				}
				li{
					&:not(:last-child){
						padding-right: 20px;
						@media (max-width: $md-width){
							padding-right: 0;
							padding-bottom: 20px;
						}
					}
					a{
						font-family: $poppinslight;
						@include font-size(13,19);
						color:$lightblack;
						background: url(#{$images}/content/button-stroke.png) no-repeat center left;
						background-size: auto 100%;
						padding: 14px;
						white-space: nowrap;
						i{
							font-size: 23px;
							color:$blue;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}