.common-structure{
	padding: 86px 50px;
	@media (max-width: $md-width){
		padding: 30px;
	}
	&.path-active{
		padding-top: 40px;
	}
	h2{
		font-family: $poppinssemibold;
		@include font-size(35,44);
		color:$darkblue;
		position: relative;
		border-bottom: 1px solid #e4e4e4;
		padding-bottom: 12px;
		margin-bottom: 40px;
		@media (max-width: $md-width){
			display: none;
		}
		&:before{
			content:"";
			width: 160px;
			height: 1px;
			background: $darkblue;
			position: absolute;
			left: 0;
			bottom:-1px;
		}
	}
	&__left-menu{
		ul{
			max-width: 183px;
			@media (max-width: $md-width){
				display: none;
			}
			li{
				&:not(:last-child){
					margin-bottom: 5px;
				}
				a{
					font-family: $poppinsregular;
					@include font-size(13,16);
					color:$lightblack;
					display: block;
					background: $butongrey;
					padding: 12px 10px 12px 27px;
					position: relative;
					@include transition;
					&:before{
						content:"";
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 4px 0 4px 8px;
						border-color: transparent transparent transparent #e2dddd;
						position: absolute;
						left: 13px;
						top: 50%;
						transform: translate(0,-50%);
						@include transition;
					}
					&.active,&:hover{
						color:$white;
						background: $darkblue;
						&:before{
							border-color: transparent transparent transparent #1e85cf;
						}
					}
				}
			}
		}
		.dropdown{
			display: none;
			margin-bottom: 40px;
			@media (max-width: $md-width){
				display: block;
			}
			button{
				font-family: $poppinsregular;
				@include font-size(13,13);
				color:$white !important;
				display: block;
				background: $darkblue !important;
				padding: 16px 35px 16px 15px;
				width: 100%;
				border-radius: 0;
				text-align: left;
				white-space: initial;
				&.dropdown-toggle{
					&:after{
						border-top: .6em solid;
					    border-right: .4em solid transparent;
					    border-left: .4em solid transparent;
					    position: absolute;
					    right: 20px;
					    top: 50%;
					    transform: translate(0,-50%);
					}
				}
			}
			.dropdown-menu{
				width: 100%;
				border-radius: 0;
				padding: 0;
				background: $white;
				margin:0;
				border-top: none;
				a{
					font-family: $poppinsregular;
					@include font-size(13,13);
					color:$lightblack;
					padding: 13px 15px 13px 27px;
					background: none;
					&:hover{
						background:$butongrey;
					}
					&.active{
						color:$darkblue;
					}
				}
			}
		}
	}
}