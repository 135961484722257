.contact{
	&__content{
		&._row{
			.row{
				> div{
					&.col-lg-6{
						&:first-child{
							border-right:1px solid #e5e5e5;
							@media (max-width: $md-width){
								border-right: none;
							}
						}
					}
				}
			}
		}
		&._branches_list{
			.row{
				> div{
					&.col-lg-6{
						margin-bottom: 30px;
					}
				}
			}
		}
		#map{
			height: 200px;
			width: 100%;
			margin-bottom: 55px;
			border:1px solid #ddd;
			@media (max-width: $md-width){
				margin-bottom: 30px;
			}
		}
		h3{
			font-family: $poppinssemibold;
			@include font-size(35,42);
			color:#f1f1f1;
			margin-bottom: 20px;
			@media (max-width: $lg-width){
				@include font-size(28,36);
			}
			span{
				font-family: $poppinslight;
			}
		}
		&_information{
			address{
				font-family: $poppinsregular;
				@include font-size(14,21);
				color:#505050;
				margin-bottom: 20px;
			}
			ul{
				li{
					&:not(:last-child){
						margin-bottom: 15px;
					}
					a{
						font-family: $poppinsregular;
						@include font-size(14,21);
						color:#505050;
						i{
							font-size: 22px;
							color:$darkblue;
							margin-right: 9px;
						}
					}
				}
			}
		}
		&_form{
			padding:0 40px;
			@media (max-width: $lg-width){
				padding:0 25px;
			}
			@media (max-width: $md-width){
				padding:40px 0 0 0;
			}
			&_wrap{
				margin-bottom: 17px;
				&.button{
					padding-top: 15px;
				}
			}
			input,textarea{
				font-family: $poppinsregular;
				@include font-size(13,17);
				color:#545454;
				border-bottom: 1px solid #d8d8d8;
				padding:7px 0;
				width: 100%;
				resize: none;
				@include transition;
				&:focus{
					@include placeholder{
						color:$darkblue;
					}
					border-bottom-color:$darkblue;
				}
			}
			button{
				padding: 12px 15px;
				font-family: $poppinsregular;
				@include font-size(14,20);
				color:$white;
				background: $darkblue;
				border-radius: 3px;
				width: 100%;
				i{
					font-size: 22px;
					margin-right: 7px;
				}
			}
			label{
				&.error{
					font-family: $poppinslight;
					@include font-size(11,15);
					color:red;
					margin-top: 5px;
				}
			}
		}
		&_branches{
			border:1px solid #dcdcdc;
			border-radius: 5px;
			height: 100%;
			padding-bottom: 46px;
			position: relative;
			@media (max-width: $xs-width){
				padding-bottom: 71px;
			}
			section{
				padding:22px 20px;
				h4{
					font-family: $poppinsregular;
					@include font-size(15,20);
					color:$lightblack;
					margin-bottom: 17px;
					span{
						font-family: $poppinssemibold;
					}
				}
				p{
					font-family: $poppinsregular;
					@include font-size(14,20);
					color:$lightblack;
				}
			}
			ul{
				display: flex;
				align-items: center;
				justify-content: center;
				border-top: 1px solid #dcdcdc;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				li{
					width: 50%;
					&:first-child{
						border-right:1px solid #dcdcdc;
					}
					a{
						display: block;
						font-family: $poppinsregular;
						@include font-size(14,20);
						color:$lightblack;
						text-align: center;
						padding: 12px 5px;
						i{
							font-size: 22px;
							margin-right: 6px;
							color:$darkblue;
							@media (max-width: $xs-width){
								display: block;
								margin-right: 0;
								margin-bottom: 7px;
							}
						}
					}
				}
			}
		}
	}
}
.alert{
	font-family: $poppinsregular;
	@include font-size(14,18);
}