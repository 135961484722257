[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "\e90e";
}
.icon-right-arrow:before {
  content: "\e90d";
}
.icon-add:before {
  content: "\e900";
}
.icon-direct:before {
  content: "\e901";
}
.icon-download:before {
  content: "\e902";
}
.icon-envelope:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-icon:before {
  content: "\e905";
  color: #056ab3;
}
.icon-image:before {
  content: "\e906";
}
.icon-instagram:before {
  content: "\e907";
}
.icon-location:before {
  content: "\e908";
}
.icon-paper:before {
  content: "\e909";
}
.icon-phone-call:before {
  content: "\e90a";
}
.icon-placeholder:before {
  content: "\e90b";
}
.icon-plus:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e90f";
}
.icon-writing:before {
  content: "\e910";
}