footer{
	margin-top: 25px;
	.footer__top{
		background: $lightblack;
		padding: 22px 0;
		@media (max-width: $md-width){
			padding: 30px 0;
		}
		figure{
			@media (max-width: $md-width){
				text-align: center;
			}
			img{
				max-width: 140px;
			}
		}
		ul{
			display: flex;
			align-items: center;
			margin-top: 10px;
			justify-content: center;
			@media (max-width: $md-width){
				flex-direction: column;
				margin:25px 0;
			}
			li{
				&:not(:last-child){
					padding-right: 35px;
					@media (max-width: $md-width){
						padding:0 0 10px 0;
					}
				}
				a{
					font-family: $poppinsregular;
					@include font-size(13,18);
					color:$white;
				}
			}
			&.footer__top_social{
				justify-content: flex-end;
				margin-top: 5px;
				@media (max-width: $md-width){
					flex-direction: row;
					justify-content: center;
					margin: 0;
				}
				li{
					&:not(:last-child){
						padding-right: 15px;
						@media (max-width: $md-width){
							padding-bottom: 0;
						}
					}
					a{
						i{
							font-size: 20px;
							color:$white;
							@include transition;
							&.icon-youtube{
								font-size: 26px;
								margin-top: 2px;
							}
						}
						&:hover{
							i{
								color:$darkblue;
							}
						}
					}
				}
			}
		}
	}
	.footer__bottom{
		background: url(#{$images}/content/header-footer-bg.jpg) no-repeat center;
		background-size: cover;
		padding: 19px 0;
		p{
			font-family: $poppinsregular;
			@include font-size(11,14);
			color:$lightblack;
			margin-top: 6px;
			@media (max-width: $md-width){
				text-align: center;
				margin-bottom: 15px;
			}
		}
		figure{
			float: right;
			@media (max-width: $md-width){
				float: none;
				text-align: center;
			}
			img{
				max-width: 71px;
			}
		}
	}
}