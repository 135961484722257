.wrapper{
	position: relative;
	z-index: 1;
	@media (max-width: $md-width){
		margin-top: 25px;
	}
	&__first-title{
		padding:10px 0;
		h1{
			font-family: $poppinssemibold;
			@include font-size(35,44);
			color:$white;
			@media (max-width: $md-width){
				color:$lightblack;
				@include font-size(24,33);
			}
		}
	}
	&__content{
		background: $white;
	}
}