html {
  min-height: 100%;
  min-width: 100%;
  font-size: 62.5%; }

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-width: 100%;
  background: #ffffff; }

*::selection {
  background: #000000;
  color: #ffffff; }

/*************************reset-erd*************************/
h1, h2, h3, h4, h5, h6, th, p, label, figure {
  font-weight: normal;
  margin: 0; }

a {
  text-decoration: none !important; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

input, select, textarea, button, a {
  outline: none !important;
  border: none; }

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0; }

input, select, textarea {
  background: #ffffff;
  border-radius: 0; }

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type=text]::-ms-clear {
  display: none; }

i {
  display: inline-block;
  vertical-align: middle; }

nav {
  background: none;
  border: none;
  margin: 0;
  min-height: auto !important; }

nav button.navbar-toggle {
  background: none !important;
  border: none !important; }

nav.navbar-expand-lg .navbar-nav a.nav-link {
  padding: 0;
  margin: 0; }

.navbar-collapse {
  border: none !important;
  box-shadow: 0 0 0 0 !important;
  padding: 0; }

.navbar-brand {
  padding: 0;
  height: auto; }

.dropdown button {
  box-shadow: 0 0 0 0 !important;
  border: none;
  padding: 0; }

/*************************reset-erd*************************/
[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-youtube:before {
  content: "\e90e"; }

.icon-right-arrow:before {
  content: "\e90d"; }

.icon-add:before {
  content: "\e900"; }

.icon-direct:before {
  content: "\e901"; }

.icon-download:before {
  content: "\e902"; }

.icon-envelope:before {
  content: "\e903"; }

.icon-facebook:before {
  content: "\e904"; }

.icon-icon:before {
  content: "\e905";
  color: #056ab3; }

.icon-image:before {
  content: "\e906"; }

.icon-instagram:before {
  content: "\e907"; }

.icon-location:before {
  content: "\e908"; }

.icon-paper:before {
  content: "\e909"; }

.icon-phone-call:before {
  content: "\e90a"; }

.icon-placeholder:before {
  content: "\e90b"; }

.icon-plus:before {
  content: "\e90c"; }

.icon-twitter:before {
  content: "\e90f"; }

.icon-writing:before {
  content: "\e910"; }

@font-face {
  font-family: 'Poppins_Bold';
  src: url("../fonts/Poppins-Bold.eot");
  src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.ttf") format("truetype"), url("../fonts/Poppins-Bold.svg#Poppins") format("svg"); }

@font-face {
  font-family: 'Poppins_Light';
  src: url("../fonts/Poppins-Light.eot");
  src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Light.woff2") format("woff2"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype"), url("../fonts/Poppins-Light.svg#Poppins Light") format("svg"); }

@font-face {
  font-family: 'Poppins_Medium';
  src: url("../fonts/Poppins-Medium.eot");
  src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype"), url("../fonts/Poppins-Medium.svg#Poppins Medium") format("svg"); }

@font-face {
  font-family: 'Poppins_Regular';
  src: url("../fonts/Poppins-Regular.eot");
  src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype"), url("../fonts/Poppins-Regular.svg#Poppins") format("svg"); }

@font-face {
  font-family: 'Poppins_SemiBold';
  src: url("../fonts/Poppins-SemiBold.eot");
  src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"), url("../fonts/Poppins-SemiBold.svg#Poppins SemiBold") format("svg"); }

@font-face {
  font-family: 'Proxima_Nova_Bold';
  src: url("../fonts/ProximaNova-Bold.eot");
  src: url("../fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff"), url("../fonts/ProximaNova-Bold.ttf") format("truetype"), url("../fonts/ProximaNova-Bold.svg#Proxima Nova") format("svg"); }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?9bnil7");
  src: url("../fonts/icomoon.eot?9bnil7#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?9bnil7") format("truetype"), url("../fonts/icomoon.woff?9bnil7") format("woff"), url("../fonts/icomoon.svg?9bnil7#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.bg {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important; }

header {
  padding: 24px 0;
  background: #fff url(../images/content/header-footer-bg.jpg) no-repeat center;
  background-size: cover; }
  @media (max-width: 991px) {
    header {
      padding: 10px 0;
      background-image: none;
      position: fixed !important;
      top: 0;
      width: 100%;
      z-index: 999;
      min-width: 320px; } }
  header.sub-header {
    background: none;
    position: relative;
    margin-bottom: 90px; }
    @media (max-width: 991px) {
      header.sub-header {
        background: #1a1a18; } }
    header.sub-header:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 336px;
      background: url(../images/content/subpage-banner.jpg) no-repeat center;
      background-size: cover;
      z-index: 0; }
      @media (max-width: 991px) {
        header.sub-header:before {
          display: none; } }
    header.sub-header nav .mobile-speed-nav li a.offer-button {
      background: #111111; }
    header.sub-header nav .mobile-speed-nav button.navbar-toggler span.navbar-toggler-icon {
      background: #fff; }
      header.sub-header nav .mobile-speed-nav button.navbar-toggler span.navbar-toggler-icon:before, header.sub-header nav .mobile-speed-nav button.navbar-toggler span.navbar-toggler-icon:after {
        background: #fff; }
    @media (max-width: 991px) {
      header.sub-header nav ul li {
        border-top-color: #000; } }
    header.sub-header nav ul li a {
      color: #fff; }
    header.sub-header nav ul.nav-right li a.offer-button {
      background: #111111; }
  header nav {
    margin: 0;
    padding: 0 !important;
    align-items: flex-start !important; }
    header nav .navbar-brand img {
      max-width: 230px;
      width: 100%; }
      @media (max-width: 576px) {
        header nav .navbar-brand img {
          max-width: 190px; } }
      @media (max-width: 375px) {
        header nav .navbar-brand img {
          max-width: 140px; } }
    header nav .navbar-collapse {
      justify-content: space-around; }
    header nav .mobile-speed-nav {
      padding: 0;
      display: none; }
      @media (max-width: 991px) {
        header nav .mobile-speed-nav {
          display: flex;
          align-items: center;
          margin-top: 8px; } }
      @media (max-width: 576px) {
        header nav .mobile-speed-nav {
          margin-top: 5px; } }
      @media (max-width: 375px) {
        header nav .mobile-speed-nav {
          margin-top: 0; } }
      header nav .mobile-speed-nav li {
        padding: 0 5px;
        border: none; }
        header nav .mobile-speed-nav li a {
          font-family: "Poppins_Medium";
          font-size: 14px;
          display: inline-block;
          padding: 9px 7px;
          color: #fff;
          width: 37px;
          height: 37px;
          text-align: center; }
          header nav .mobile-speed-nav li a i {
            font-size: 20px;
            color: #fff; }
          header nav .mobile-speed-nav li a.offer-button {
            background: #1a1a18; }
          header nav .mobile-speed-nav li a.lang-button {
            background: #1f84df; }
    header nav button.navbar-toggler {
      padding: 0;
      margin: 0;
      height: 32px;
      width: 35px; }
      header nav button.navbar-toggler span.navbar-toggler-icon {
        width: 32px;
        height: 2.5px;
        background: #1a1a18;
        position: relative; }
        header nav button.navbar-toggler span.navbar-toggler-icon:before, header nav button.navbar-toggler span.navbar-toggler-icon:after {
          content: "";
          width: 32px;
          height: 2.5px;
          background: #1a1a18;
          position: absolute;
          display: inline-block;
          left: 0; }
        header nav button.navbar-toggler span.navbar-toggler-icon:before {
          top: -12px; }
        header nav button.navbar-toggler span.navbar-toggler-icon:after {
          bottom: -12px; }
    header nav ul {
      display: flex;
      padding-top: 4px; }
      header nav ul li {
        padding: 0 15px; }
        header nav ul li:last-child {
          padding-right: 0; }
        @media (max-width: 1199px) {
          header nav ul li {
            padding: 0 7px; } }
        @media (max-width: 991px) {
          header nav ul li {
            padding: 8px 0;
            border-top: 1px solid #f3f3f3; } }
        header nav ul li a {
          font-family: "Poppins_Regular";
          font-size: 1.3rem;
          line-height: 1.7rem;
          color: #1a1a18;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
          header nav ul li a:hover {
            color: #1f84df; }
          header nav ul li a.active {
            color: #056ab3; }
      header nav ul.navbar-nav {
        margin: 0 auto; }
      @media (max-width: 991px) {
        header nav ul.nav-right {
          display: none; } }
      header nav ul.nav-right li {
        padding: 0; }
        header nav ul.nav-right li:not(:last-child) {
          padding-right: 5px; }
        header nav ul.nav-right li a {
          font-size: 1.4rem;
          line-height: 2.1rem;
          font-family: "Poppins_Medium";
          color: #fff;
          padding: 13px 16px;
          display: inline-block;
          text-align: center; }
          header nav ul.nav-right li a.offer-button {
            background: #1a1a18;
            min-width: 150px; }
            @media (max-width: 1199px) {
              header nav ul.nav-right li a.offer-button {
                min-width: auto; } }
          header nav ul.nav-right li a.lang-button {
            background: #1f84df; }
          header nav ul.nav-right li a i {
            margin-right: 7px;
            font-size: 20px;
            color: #fff; }

a.offer-button {
  position: relative; }
  a.offer-button span {
    position: absolute;
    left: -7px;
    top: -7px;
    width: 22px;
    height: 22px;
    background: #1f84df;
    border-radius: 50%;
    font-size: 1.2rem;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center; }

.swal2-popup {
  font-size: 150% !important;
  max-width: 500px;
  width: 100%; }
  .swal2-popup .swal2-title {
    font-family: "Poppins_Medium";
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #1a1a18; }
  .swal2-popup .swal2-html-container {
    font-family: "Poppins_Regular";
    font-size: 1.5rem;
    line-height: 2rem;
    color: #1a1a18; }
  .swal2-popup .swal2-confirm {
    font-family: "Poppins_Medium";
    font-size: 1.5rem;
    line-height: 2rem;
    color: #fff;
    background: #056ab3;
    outline: none;
    box-shadow: 0 0 0 0; }

@media (max-width: 991px) {
  body {
    padding-top: 73.47px; } }

@media (max-width: 576px) {
  body {
    padding-top: 68.41px; } }

@media (max-width: 375px) {
  body {
    padding-top: 57px; } }

footer {
  margin-top: 25px; }
  footer .footer__top {
    background: #1a1a18;
    padding: 22px 0; }
    @media (max-width: 991px) {
      footer .footer__top {
        padding: 30px 0; } }
    @media (max-width: 991px) {
      footer .footer__top figure {
        text-align: center; } }
    footer .footer__top figure img {
      max-width: 140px; }
    footer .footer__top ul {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: center; }
      @media (max-width: 991px) {
        footer .footer__top ul {
          flex-direction: column;
          margin: 25px 0; } }
      footer .footer__top ul li:not(:last-child) {
        padding-right: 35px; }
        @media (max-width: 991px) {
          footer .footer__top ul li:not(:last-child) {
            padding: 0 0 10px 0; } }
      footer .footer__top ul li a {
        font-family: "Poppins_Regular";
        font-size: 1.3rem;
        line-height: 1.8rem;
        color: #fff; }
      footer .footer__top ul.footer__top_social {
        justify-content: flex-end;
        margin-top: 5px; }
        @media (max-width: 991px) {
          footer .footer__top ul.footer__top_social {
            flex-direction: row;
            justify-content: center;
            margin: 0; } }
        footer .footer__top ul.footer__top_social li:not(:last-child) {
          padding-right: 15px; }
          @media (max-width: 991px) {
            footer .footer__top ul.footer__top_social li:not(:last-child) {
              padding-bottom: 0; } }
        footer .footer__top ul.footer__top_social li a i {
          font-size: 20px;
          color: #fff;
          -webkit-transition: .4s ease-in-out;
          -moz-transition: .4s ease-in-out;
          -ms-transition: .4s ease-in-out;
          -o-transition: .4s ease-in-out;
          transition: .4s ease-in-out; }
          footer .footer__top ul.footer__top_social li a i.icon-youtube {
            font-size: 26px;
            margin-top: 2px; }
        footer .footer__top ul.footer__top_social li a:hover i {
          color: #056ab3; }
  footer .footer__bottom {
    background: url(../images/content/header-footer-bg.jpg) no-repeat center;
    background-size: cover;
    padding: 19px 0; }
    footer .footer__bottom p {
      font-family: "Poppins_Regular";
      font-size: 1.1rem;
      line-height: 1.4rem;
      color: #1a1a18;
      margin-top: 6px; }
      @media (max-width: 991px) {
        footer .footer__bottom p {
          text-align: center;
          margin-bottom: 15px; } }
    footer .footer__bottom figure {
      float: right; }
      @media (max-width: 991px) {
        footer .footer__bottom figure {
          float: none;
          text-align: center; } }
      footer .footer__bottom figure img {
        max-width: 71px; }

.main-slider {
  position: relative; }
  .main-slider:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1; }
  .main-slider .carousel-indicators {
    display: none; }
    @media (max-width: 1199px) {
      .main-slider .carousel-indicators {
        display: flex; } }
    .main-slider .carousel-indicators li {
      background-color: #1f84df; }
  .main-slider figure {
    height: 569px;
    width: 100%; }
    @media (max-width: 991px) {
      .main-slider figure {
        height: 400px; } }
  .main-slider__title {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center; }
    .main-slider__title h1 {
      font-family: "Poppins_Semibold";
      font-size: 3.7rem;
      line-height: 4.6rem;
      color: #fff;
      margin-bottom: 15px; }
      @media (max-width: 991px) {
        .main-slider__title h1 {
          font-size: 2.8rem;
          line-height: 3.6rem; } }
      .main-slider__title h1 span {
        display: block; }
    .main-slider__title p {
      font-family: "Poppins_Light";
      font-size: 1.3rem;
      line-height: 1.8rem;
      color: #fff; }
  .main-slider a.carousel-control {
    width: 53px;
    height: 53px;
    background: #1f84df;
    opacity: 1;
    top: 50%;
    z-index: 3;
    transform: translate(0, -50%); }
    @media (max-width: 1199px) {
      .main-slider a.carousel-control {
        display: none; } }
    .main-slider a.carousel-control.carousel-control-prev:before {
      transform: rotate(180deg); }
    .main-slider a.carousel-control.carousel-control-prev:before, .main-slider a.carousel-control.carousel-control-next:before {
      font-family: 'icomoon';
      content: "\e90d";
      font-size: 25px;
      color: #fff; }
  .main-slider .carousel-item h1 span, .main-slider .carousel-item p, .main-slider .carousel-item a {
    animation-duration: var(--animate-duration);
    animation-fill-mode: both; }
  .main-slider .carousel-item h1 span {
    opacity: 0; }
    .main-slider .carousel-item h1 span:before {
      animation-duration: var(--animate-duration);
      animation-fill-mode: both; }
  .main-slider .carousel-item p {
    opacity: 0; }
  .main-slider .carousel-item.active h1 span {
    animation-name: fadeInLeft; }
    .main-slider .carousel-item.active h1 span:nth-child(1) {
      animation-delay: 0.4s; }
    .main-slider .carousel-item.active h1 span:nth-child(2) {
      animation-delay: 0.7s; }
  .main-slider .carousel-item.active p {
    animation-name: fadeInUp;
    animation-delay: 1s; }

.main-hero {
  margin: 38px 0 35px 0; }
  @media (max-width: 991px) {
    .main-hero {
      margin: 15px 0; } }
  .main-hero__plus {
    display: inline-block;
    width: 46px;
    height: 46px;
    background: #056ab3;
    text-align: center;
    position: absolute;
    right: 24px;
    top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5; }
    .main-hero__plus i {
      font-size: 14px;
      color: #fff; }
  .main-hero__title {
    padding: 24px 15px 20px 40px;
    position: relative; }
    @media (max-width: 767px) {
      .main-hero__title {
        padding-left: 24px; } }
    .main-hero__title:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 50%;
      background: #e8e8e8;
      background: linear-gradient(90deg, #e8e8e8 47%, white 100%); }
    .main-hero__title:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 30%;
      height: 1px;
      background: #056ab3; }
    .main-hero__title h3 {
      font-family: "Proxima_Nova_Bold";
      font-size: 1.8rem;
      line-height: 2.7rem;
      color: #1a1a18; }
      .main-hero__title h3 i {
        margin-right: 10px;
        font-size: 24px;
        vertical-align: text-bottom; }
  .main-hero section {
    padding: 25px 15px 37px 40px; }
    @media (max-width: 767px) {
      .main-hero section {
        padding-left: 24px; } }
    .main-hero section h5 {
      font-family: "Proxima_Nova_Bold";
      font-size: 1.6rem;
      line-height: 2rem;
      color: #1a1a18;
      margin-bottom: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden; }
      .main-hero section h5 span {
        display: block; }
    .main-hero section p {
      font-family: "Poppins_Regular";
      font-size: 1.4rem;
      line-height: 2rem;
      color: #1a1a18;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden; }
  .main-hero__corporate:before, .main-hero__corporate:after, .main-hero__answers:before, .main-hero__answers:after {
    content: "";
    position: absolute;
    z-index: 5;
    background: #fff; }
  .main-hero__corporate:before, .main-hero__answers:before {
    bottom: 24px;
    width: 1px;
    height: 60%;
    right: 24px; }
  .main-hero__corporate:after, .main-hero__answers:after {
    bottom: 24px;
    width: 40%;
    height: 1px;
    right: 24px; }
  .main-hero__corporate {
    background: #fff url(../images/content/main-corporate.jpg) no-repeat center right;
    padding-right: 280px;
    background-size: cover;
    position: relative;
    height: 280px; }
    @media (max-width: 991px) {
      .main-hero__corporate {
        padding-right: 50px;
        margin-bottom: 15px; } }
  .main-hero__answers {
    background: #fff;
    position: relative;
    height: 280px; }
    .main-hero__answers .carousel {
      height: 100%; }
      .main-hero__answers .carousel .carousel-inner {
        height: 100%; }
    .main-hero__answers .main-hero__title {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; }
    .main-hero__answers section {
      padding-top: 96px;
      padding-right: 230px; }
      @media (max-width: 1199px) {
        .main-hero__answers section {
          padding-right: 160px; } }
      @media (max-width: 991px) {
        .main-hero__answers section {
          padding-right: 240px; } }
      @media (max-width: 576px) {
        .main-hero__answers section {
          padding-right: 140px; } }
      .main-hero__answers section h5 {
        display: block;
        font-size: 1.7rem;
        line-height: 2.1rem; }
        @media (max-width: 375px) {
          .main-hero__answers section h5 {
            font-size: 1.4rem;
            line-height: 1.8rem; } }
      .main-hero__answers section p {
        -webkit-line-clamp: 3; }
    .main-hero__answers figure {
      max-width: 218px;
      width: 100%;
      height: 280px;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0; }
      @media (max-width: 1199px) {
        .main-hero__answers figure {
          max-width: 148px; } }
      @media (max-width: 991px) {
        .main-hero__answers figure {
          max-width: 220px; } }
      @media (max-width: 576px) {
        .main-hero__answers figure {
          max-width: 128px; } }
    .main-hero__answers .answer-slide__control {
      position: absolute;
      max-width: 66px;
      width: 100%;
      height: 33px;
      bottom: 20px;
      left: 40px; }
      @media (max-width: 767px) {
        .main-hero__answers .answer-slide__control {
          left: 24px; } }
      .main-hero__answers .answer-slide__control a {
        background: #056ab3;
        width: 50%;
        opacity: 1; }
        .main-hero__answers .answer-slide__control a:last-child {
          background: #fff;
          border: 1px solid #056ab3; }
        .main-hero__answers .answer-slide__control a.carousel-control-prev:before, .main-hero__answers .answer-slide__control a.carousel-control-next:before {
          font-family: 'icomoon';
          content: "\e90d";
          font-size: 16px;
          color: #056ab3; }
        .main-hero__answers .answer-slide__control a.carousel-control-prev:before {
          transform: rotate(180deg);
          color: #fff; }
    .main-hero__answers .carousel-item h5, .main-hero__answers .carousel-item p {
      animation-duration: var(--animate-duration);
      animation-fill-mode: both;
      opacity: 0; }
    .main-hero__answers .carousel-item.active h5 {
      animation-name: fadeInDown; }
    .main-hero__answers .carousel-item.active p {
      animation-name: fadeInLeft;
      animation-delay: 0.4s; }

.main-projects__text {
  background: #fff;
  height: 236px;
  position: relative; }
  .main-projects__text:before {
    content: "";
    left: 100%;
    width: 30px;
    height: 100%;
    background: #fff;
    display: inline-block;
    top: 0;
    position: absolute; }
    @media (max-width: 991px) {
      .main-projects__text:before {
        content: none; } }
  .main-projects__text_title {
    padding: 24px 15px 20px 40px;
    position: relative; }
    @media (max-width: 767px) {
      .main-projects__text_title {
        padding-left: 24px; } }
    .main-projects__text_title:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 50%;
      background: #e8e8e8;
      background: linear-gradient(90deg, #e8e8e8 47%, white 100%); }
    .main-projects__text_title:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 30%;
      height: 1px;
      background: #056ab3; }
    .main-projects__text_title h3 {
      font-family: "Proxima_Nova_Bold";
      font-size: 1.8rem;
      line-height: 2.7rem;
      color: #1a1a18; }
      .main-projects__text_title h3 i {
        margin-right: 10px;
        font-size: 24px;
        vertical-align: text-bottom; }
  .main-projects__text section {
    padding: 23px 0 28px 40px;
    position: relative; }
    @media (max-width: 767px) {
      .main-projects__text section {
        padding-left: 24px; } }
    .main-projects__text section p {
      font-family: "Poppins_Regular";
      font-size: 1.4rem;
      line-height: 2rem;
      color: #1a1a18;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden; }
    .main-projects__text section .swiper-control {
      position: absolute;
      max-width: 66px;
      width: 100%;
      height: 33px;
      bottom: -25px;
      left: 40px; }
      @media (max-width: 767px) {
        .main-projects__text section .swiper-control {
          left: 24px; } }
      .main-projects__text section .swiper-control__button {
        background: #056ab3;
        width: 50%;
        opacity: 1;
        outline: none;
        height: 33px;
        margin: 0;
        top: auto; }
        .main-projects__text section .swiper-control__button:last-child {
          background: #fff;
          border: 1px solid #056ab3; }
        .main-projects__text section .swiper-control__button:before {
          font-family: 'icomoon';
          content: "\e90d";
          font-size: 16px;
          color: #056ab3; }
        .main-projects__text section .swiper-control__button:after {
          content: none; }
        .main-projects__text section .swiper-control__button.swiper-button-prev {
          left: 0;
          right: auto; }
          .main-projects__text section .swiper-control__button.swiper-button-prev:before {
            transform: rotate(180deg);
            color: #fff; }
        .main-projects__text section .swiper-control__button.swiper-button-next {
          right: 0;
          left: auto; }

.main-projects .swiper-container .swiper-slide:nth-child(3n+1):before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(5, 106, 179, 0.7); }

.main-projects .swiper-container .swiper-slide:nth-child(3n+2) figure {
  filter: grayscale(100%); }

.main-projects .swiper-container .swiper-slide:nth-child(3n+0):before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(40, 40, 40, 0.7); }

.main-projects .swiper-container .swiper-slide:nth-child(3n+0) figure {
  filter: grayscale(80%); }

.main-projects .swiper-container .swiper-slide .main-projects__card-information {
  position: absolute;
  bottom: 0;
  padding: 15px 25px 23px 25px; }
  .main-projects .swiper-container .swiper-slide .main-projects__card-information span {
    display: block;
    font-family: "Poppins_Regular";
    font-size: 1.5rem;
    line-height: 2rem;
    color: #fff;
    position: relative; }
    .main-projects .swiper-container .swiper-slide .main-projects__card-information span:before {
      content: "";
      position: absolute;
      background: #fff;
      height: 1px;
      left: 50px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%); }
  .main-projects .swiper-container .swiper-slide .main-projects__card-information p {
    font-family: "Poppins_Light";
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #fff;
    margin-top: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; }

.main-projects .swiper-container .swiper-slide span.plus {
  display: inline-block;
  border: 1px solid #fff;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; }
  .main-projects .swiper-container .swiper-slide span.plus i {
    font-size: 16px;
    color: #fff; }

.main-projects .swiper-container figure {
  height: 236px;
  width: 100%; }

.wrapper {
  position: relative;
  z-index: 1; }
  @media (max-width: 991px) {
    .wrapper {
      margin-top: 25px; } }
  .wrapper__first-title {
    padding: 10px 0; }
    .wrapper__first-title h1 {
      font-family: "Poppins_Semibold";
      font-size: 3.5rem;
      line-height: 4.4rem;
      color: #fff; }
      @media (max-width: 991px) {
        .wrapper__first-title h1 {
          color: #1a1a18;
          font-size: 2.4rem;
          line-height: 3.3rem; } }
  .wrapper__content {
    background: #fff; }

.common-structure {
  padding: 86px 50px; }
  @media (max-width: 991px) {
    .common-structure {
      padding: 30px; } }
  .common-structure.path-active {
    padding-top: 40px; }
  .common-structure h2 {
    font-family: "Poppins_Semibold";
    font-size: 3.5rem;
    line-height: 4.4rem;
    color: #056ab3;
    position: relative;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 12px;
    margin-bottom: 40px; }
    @media (max-width: 991px) {
      .common-structure h2 {
        display: none; } }
    .common-structure h2:before {
      content: "";
      width: 160px;
      height: 1px;
      background: #056ab3;
      position: absolute;
      left: 0;
      bottom: -1px; }
  .common-structure__left-menu ul {
    max-width: 183px; }
    @media (max-width: 991px) {
      .common-structure__left-menu ul {
        display: none; } }
    .common-structure__left-menu ul li:not(:last-child) {
      margin-bottom: 5px; }
    .common-structure__left-menu ul li a {
      font-family: "Poppins_Regular";
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: #1a1a18;
      display: block;
      background: #f5f5f5;
      padding: 12px 10px 12px 27px;
      position: relative;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out; }
      .common-structure__left-menu ul li a:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 8px;
        border-color: transparent transparent transparent #e2dddd;
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translate(0, -50%);
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
      .common-structure__left-menu ul li a.active, .common-structure__left-menu ul li a:hover {
        color: #fff;
        background: #056ab3; }
        .common-structure__left-menu ul li a.active:before, .common-structure__left-menu ul li a:hover:before {
          border-color: transparent transparent transparent #1e85cf; }
  .common-structure__left-menu .dropdown {
    display: none;
    margin-bottom: 40px; }
    @media (max-width: 991px) {
      .common-structure__left-menu .dropdown {
        display: block; } }
    .common-structure__left-menu .dropdown button {
      font-family: "Poppins_Regular";
      font-size: 1.3rem;
      line-height: 1.3rem;
      color: #fff !important;
      display: block;
      background: #056ab3 !important;
      padding: 16px 35px 16px 15px;
      width: 100%;
      border-radius: 0;
      text-align: left;
      white-space: initial; }
      .common-structure__left-menu .dropdown button.dropdown-toggle:after {
        border-top: .6em solid;
        border-right: .4em solid transparent;
        border-left: .4em solid transparent;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%); }
    .common-structure__left-menu .dropdown .dropdown-menu {
      width: 100%;
      border-radius: 0;
      padding: 0;
      background: #fff;
      margin: 0;
      border-top: none; }
      .common-structure__left-menu .dropdown .dropdown-menu a {
        font-family: "Poppins_Regular";
        font-size: 1.3rem;
        line-height: 1.3rem;
        color: #1a1a18;
        padding: 13px 15px 13px 27px;
        background: none; }
        .common-structure__left-menu .dropdown .dropdown-menu a:hover {
          background: #f5f5f5; }
        .common-structure__left-menu .dropdown .dropdown-menu a.active {
          color: #056ab3; }

.corporate__content p {
  font-family: "Poppins_Light";
  font-size: 1.4rem;
  line-height: 2rem;
  color: #1a1a18; }

.corporate__content_banner {
  margin-bottom: 20px; }
  .corporate__content_banner figure {
    height: 250px;
    width: 100%; }

.corporate__content_partners figure {
  background: #f9f9f9;
  padding: 26px;
  height: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 30px; }
  .corporate__content_partners figure img {
    max-width: 100%;
    max-height: 44px;
    filter: grayscale(100%);
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out; }
  .corporate__content_partners figure:hover img {
    filter: grayscale(0); }

.corporate__content_references .row > div {
  margin-bottom: 30px; }

.corporate__content_references_wrap {
  padding: 40px 22px 22px 22px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  position: relative;
  height: 100%; }
  .corporate__content_references_wrap:before {
    content: "\e906";
    font-family: 'icomoon';
    font-size: 23px;
    color: #ababab;
    position: absolute;
    right: 15px;
    top: 15px;
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    visibility: hidden;
    opacity: 0; }
  .corporate__content_references_wrap figure {
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px; }
    .corporate__content_references_wrap figure img {
      max-height: 115px;
      max-width: 100%; }
  .corporate__content_references_wrap h4 {
    font-family: "Poppins_Semibold";
    font-size: 1.3rem;
    line-height: 2rem;
    color: #1a1a18;
    text-align: center; }
  .corporate__content_references_wrap.photo-active:before {
    opacity: 1;
    visibility: visible; }
  .corporate__content_references_wrap:hover:before {
    color: #056ab3; }

.corporate__content_certificates .row > div {
  margin-bottom: 30px; }

.corporate__content_certificates_wrap {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  height: 100%; }
  .corporate__content_certificates_wrap figure {
    height: 198px;
    border-bottom: 1px solid #e8e8e8;
    padding: 22px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .corporate__content_certificates_wrap figure img {
      max-height: 160px;
      max-width: 100%; }
  .corporate__content_certificates_wrap h4 {
    font-family: "Poppins_Regular";
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #1a1a18;
    padding: 14px 5px;
    text-align: center;
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out; }
  .corporate__content_certificates_wrap:hover h4 {
    color: #056ab3; }

.corporate__content_cv {
  margin-top: 40px; }
  .corporate__content_cv_title {
    margin-bottom: 40px; }
    .corporate__content_cv_title h3 {
      font-family: "Poppins_Regular";
      font-size: 2rem;
      line-height: 2.8rem;
      color: #056ab3;
      position: relative; }
      .corporate__content_cv_title h3:before {
        content: "";
        position: absolute;
        left: 150px;
        background: #e1e1e1;
        height: 1px;
        right: 0;
        top: 50%;
        margin-top: -0.5px; }
      .corporate__content_cv_title h3:after {
        content: "";
        position: absolute;
        left: 150px;
        top: 50%;
        margin-top: -0.5px;
        height: 1px;
        width: 20%;
        background: #056ab3; }
      .corporate__content_cv_title h3 span {
        font-family: "Poppins_Semibold"; }
  .corporate__content_cv_form_wrap {
    margin-bottom: 20px; }
    .corporate__content_cv_form_wrap.button {
      justify-content: flex-end; }
      @media (max-width: 991px) {
        .corporate__content_cv_form_wrap.button {
          justify-content: flex-start;
          align-items: flex-start; } }
    .corporate__content_cv_form_wrap input[type="text"] {
      border: 1px solid #e1e1e1;
      padding: 12px 15px;
      font-family: "Poppins_Regular";
      font-size: 1.5rem;
      line-height: 2rem;
      color: #1a1a18;
      width: 100%; }
    .corporate__content_cv_form_wrap input[type="file"] {
      display: none; }
    .corporate__content_cv_form_wrap label:not(.error) {
      border: 1px solid #e1e1e1;
      padding: 12px 15px;
      width: 100%;
      position: relative;
      height: 46px;
      cursor: pointer;
      font-family: "Poppins_Light";
      font-size: 1.5rem;
      line-height: 2rem;
      color: #1a1a18; }
      @media (max-width: 991px) {
        .corporate__content_cv_form_wrap label:not(.error) {
          width: 100%; } }
      .corporate__content_cv_form_wrap label:not(.error) span.iconed {
        display: inline-block;
        font-family: "Poppins_Light";
        font-size: 1.1rem;
        line-height: 1.5rem;
        color: #1a1a18;
        border-left: 1px solid #e1e1e1;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        padding: 15px; }
        .corporate__content_cv_form_wrap label:not(.error) span.iconed i {
          font-size: 15px;
          color: #056ab3;
          margin-right: 6px; }
      .corporate__content_cv_form_wrap label:not(.error) span.file-name {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50%; }
    .corporate__content_cv_form_wrap label.error {
      font-family: "Poppins_Light";
      font-size: 1.1rem;
      line-height: 1.5rem;
      color: red;
      margin-top: 5px;
      display: block; }
    .corporate__content_cv_form_wrap button {
      padding: 12px 15px;
      font-family: "Poppins_Regular";
      font-size: 1.4rem;
      line-height: 2rem;
      color: #fff;
      background: #056ab3;
      border-radius: 3px;
      min-width: 180px; }
      .corporate__content_cv_form_wrap button i {
        font-size: 22px;
        margin-right: 7px; }

.projects {
  padding: 74px 70px; }
  @media (max-width: 991px) {
    .projects {
      padding: 40px 30px; } }
  @media (max-width: 767px) {
    .projects {
      padding: 30px 15px; } }
  .projects .row > div {
    margin-bottom: 30px; }
  .projects__wrap {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 100%; }
    .projects__wrap figure {
      height: 240px;
      width: 100%;
      border-radius: 5px 5px 0 0;
      position: relative; }
      .projects__wrap figure:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #056ab3;
        opacity: 0;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -ms-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out; }
      .projects__wrap figure span {
        display: inline-block;
        width: 47px;
        height: 47px;
        background: #1f84df;
        position: absolute;
        right: 22px;
        top: 22px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .projects__wrap figure span i {
          font-size: 22px;
          color: #fff; }
    .projects__wrap section {
      padding: 24px; }
      .projects__wrap section h3 {
        font-family: "Poppins_Medium";
        font-size: 1.7rem;
        line-height: 2.4rem;
        color: #1a1a18;
        margin-bottom: 16px; }
      .projects__wrap section p {
        font-family: "Poppins_Light";
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #1a1a18; }
        .projects__wrap section p i {
          font-size: 16px;
          margin-right: 8px;
          color: #056ab3; }
    .projects__wrap:hover figure:before {
      opacity: 0.5; }

.project_detail .carousel {
  height: 100%; }
  .project_detail .carousel .carousel-inner {
    height: 100%; }
    .project_detail .carousel .carousel-inner .carousel-item {
      height: 100%; }

.project_detail ol {
  bottom: 30px; }
  @media (max-width: 991px) {
    .project_detail ol {
      bottom: 15px; } }
  .project_detail ol li {
    background-color: #fff;
    opacity: 1; }
    .project_detail ol li.active {
      background-color: #1f84df; }

.project_detail figure {
  min-height: 700px;
  height: 100%;
  width: 100%;
  position: relative; }
  .project_detail figure:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #056ab3;
    opacity: 0.3; }
  @media (max-width: 991px) {
    .project_detail figure {
      min-height: 500px; } }
  @media (max-width: 767px) {
    .project_detail figure {
      min-height: 400px; } }

.project_detail section {
  padding: 60px 60px 60px 30px; }
  @media (max-width: 991px) {
    .project_detail section {
      padding: 30px; } }
  .project_detail section p {
    font-family: "Poppins_Light";
    font-size: 1.5rem;
    line-height: 2rem;
    color: #1a1a18; }
    .project_detail section p i {
      font-size: 20px;
      color: #056ab3;
      margin-right: 7px; }
  .project_detail section h2, .project_detail section h3 {
    font-family: "Poppins_Bold";
    font-size: 2rem;
    line-height: 2.8rem;
    color: #1a1a18;
    margin: 24px 0; }
  .project_detail section h3 {
    margin-top: 35px; }
  .project_detail section table tr td {
    padding: 12px 10px;
    font-family: "Poppins_Light";
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #1a1a18;
    border-right: none;
    border-left: none;
    vertical-align: middle; }

.answers {
  padding: 70px 64px; }
  @media (max-width: 991px) {
    .answers {
      padding: 40px 30px; } }
  @media (max-width: 375px) {
    .answers {
      padding: 40px 15px; } }
  .answers__wrap {
    position: relative;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    margin-bottom: 30px;
    overflow: hidden; }
    .answers__wrap:before, .answers__wrap:after {
      content: "";
      position: absolute;
      background: #c6c6c6;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out;
      z-index: 1; }
    .answers__wrap:before {
      right: 10px;
      bottom: 10px;
      height: 80%;
      width: 1px; }
    .answers__wrap:after {
      right: 10px;
      bottom: 10px;
      height: 1px;
      width: 40%; }
    .answers__wrap figure {
      width: 100%;
      height: 210px;
      position: relative;
      background-position: left center !important;
      background-size: auto 100% !important; }
      .answers__wrap figure:before {
        content: "";
        position: absolute;
        right: 0;
        width: 100%;
        top: 0;
        height: 100%;
        background: url(../images/content/answers/answers-shadow.png) no-repeat center;
        background-position: center right -110px; }
        @media (max-width: 1199px) {
          .answers__wrap figure:before {
            background-position: center right -180px; } }
        @media (max-width: 767px) {
          .answers__wrap figure:before {
            background-position: center right -130px; } }
        @media (max-width: 576px) {
          .answers__wrap figure:before {
            background-position: center right -180px; } }
    .answers__wrap > span {
      display: inline-block;
      width: 47px;
      height: 47px;
      background: #c6c6c6;
      position: absolute;
      right: 40px;
      top: 30px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      -webkit-transition: .4s ease-in-out;
      -moz-transition: .4s ease-in-out;
      -ms-transition: .4s ease-in-out;
      -o-transition: .4s ease-in-out;
      transition: .4s ease-in-out; }
      .answers__wrap > span i {
        font-size: 22px;
        color: #fff; }
    .answers__wrap_title {
      position: absolute;
      right: 40px;
      bottom: 40px;
      text-align: right; }
      .answers__wrap_title h3 {
        font-family: "Poppins_Light";
        font-size: 1.9rem;
        line-height: 2.7rem;
        color: #1a1a18; }
        .answers__wrap_title h3 span {
          font-family: "Poppins_Semibold";
          display: block; }
    .answers__wrap:hover:before, .answers__wrap:hover:after {
      background: #056ab3; }
    .answers__wrap:hover > span {
      background: #056ab3; }

.answers-detail {
  padding: 60px; }
  @media (max-width: 1199px) {
    .answers-detail {
      padding: 60px 30px; } }
  @media (max-width: 991px) {
    .answers-detail {
      padding: 30px; } }
  .answers-detail .mobile-toggle-menu {
    display: none;
    margin-bottom: 30px; }
    @media (max-width: 991px) {
      .answers-detail .mobile-toggle-menu {
        display: block; } }
    .answers-detail .mobile-toggle-menu a {
      font-family: "Poppins_Regular";
      font-size: 1.5rem;
      line-height: 2rem;
      color: #1a1a18;
      padding: 15px 65px 15px 22px;
      border: 1px solid #dfdfdf;
      position: relative;
      border-radius: 5px;
      position: relative;
      display: block; }
      .answers-detail .mobile-toggle-menu a:before {
        content: "\e90d";
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%) rotate(90deg);
        font-family: 'icomoon'; }
  @media (max-width: 991px) {
    .answers-detail .accordion-menu {
      display: none;
      margin-bottom: 30px; } }
  .answers-detail .accordion-menu #accordion-wrapper > .card {
    border-radius: 5px; }
    .answers-detail .accordion-menu #accordion-wrapper > .card + .card {
      margin-top: 5px; }
    .answers-detail .accordion-menu #accordion-wrapper > .card > .card-header {
      padding: 0;
      background: none;
      border: none; }
      .answers-detail .accordion-menu #accordion-wrapper > .card > .card-header > .card-link {
        font-family: "Poppins_Regular";
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: #1a1a18;
        display: block;
        background: #fff;
        padding: 15px 15px 15px 20px;
        border-radius: 5px;
        position: relative; }
        .answers-detail .accordion-menu #accordion-wrapper > .card > .card-header > .card-link:before {
          content: "";
          position: absolute;
          left: -1px;
          top: 0;
          width: 3px;
          height: 100%;
          background: #056ab3;
          opacity: 0;
          border-radius: 5px 0 0 5px; }
    .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      padding: 0; }
      .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body .card {
        border: none; }
        .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body .card .card-header {
          padding: 0; }
          .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body .card .card-header .card-link {
            font-family: "Poppins_Regular";
            font-size: 1.3rem;
            line-height: 1.6rem;
            color: #1a1a18;
            display: block;
            background: #f2f5ff;
            padding: 15px 35px 15px 20px;
            position: relative; }
            .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body .card .card-header .card-link i.icon-right-arrow {
              font-size: 14px;
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translate(0, -50%);
              color: #5a5a5a;
              font-weight: bold; }
        .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body .card .card-body {
          padding: 0; }
          .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body .card .card-body .card .card-header .card-link {
            background: #fafdfd; }
            .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body .card .card-body .card .card-header .card-link i {
              font-size: 12px;
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translate(0, -50%);
              color: #5a5a5a; }
          .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body .card .card-body .card.active .card-header .card-link i {
            color: #056ab3; }
        .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body .card.active .card-header .card-link i.icon-right-arrow {
          transform: translate(0, -50%) rotate(90deg);
          color: #056ab3; }
      .answers-detail .accordion-menu #accordion-wrapper > .card > .first-collapse > .card-body > ul li:last-child {
        border-bottom: none; }
    .answers-detail .accordion-menu #accordion-wrapper > .card.active > .card-header > .card-link:before {
      opacity: 1; }
    .answers-detail .accordion-menu #accordion-wrapper > .card ul li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
      .answers-detail .accordion-menu #accordion-wrapper > .card ul li a {
        font-family: "Poppins_Regular";
        font-size: 1.3rem;
        line-height: 1.8rem;
        color: #1a1a18;
        padding: 15px 15px 15px 37px;
        position: relative;
        background: #f3f9fb;
        display: block; }
        .answers-detail .accordion-menu #accordion-wrapper > .card ul li a:before {
          content: "";
          position: absolute;
          left: 25px;
          top: 50%;
          transform: translate(0, -50%);
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #056ab3; }
  .answers-detail__content_product {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    margin-bottom: 30px; }
    .answers-detail__content_product figure {
      height: 240px;
      width: 100%;
      padding: 35px 15px;
      text-align: center; }
      .answers-detail__content_product figure img {
        max-width: 100%;
        max-height: 180px; }
    .answers-detail__content_product ol.carousel-indicators {
      position: absolute;
      left: 0;
      bottom: 10px;
      width: 100%;
      transform: translate(0, 0);
      margin: 0; }
      .answers-detail__content_product ol.carousel-indicators li {
        background-color: #1f84df; }
  .answers-detail__content a.offer-button-add {
    font-family: "Poppins_Regular";
    font-size: 1.5rem;
    line-height: 2rem;
    color: #fff;
    background: #056ab3;
    padding: 10px 25px;
    border-radius: 5px;
    display: inline-block;
    margin: 0 0 20px 0; }
  .answers-detail__content h2 {
    font-family: "Poppins_Medium";
    font-size: 1.9rem;
    line-height: 2.6rem;
    color: #1a1a18;
    margin-bottom: 14px; }
  .answers-detail__content p {
    font-family: "Poppins_Light";
    font-size: 1.4rem;
    line-height: 2rem;
    color: #1a1a18;
    margin-bottom: 25px; }
  .answers-detail__content > ul {
    margin: 30px; }
    .answers-detail__content > ul li {
      font-family: "Poppins_Light";
      font-size: 1.3rem;
      line-height: 1.9rem;
      color: #1a1a18;
      margin-bottom: 5px;
      position: relative; }
      .answers-detail__content > ul li:before {
        content: "";
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translate(0, -50%);
        width: 4px;
        height: 4px;
        background: #1a1a18;
        border-radius: 50%; }
  .answers-detail__content_title {
    margin-bottom: 14px; }
    .answers-detail__content_title h3 {
      font-family: "Poppins_Regular";
      font-size: 1.5rem;
      line-height: 2rem;
      color: #1a1a18;
      padding: 15px 22px;
      border: 1px solid #dfdfdf;
      position: relative;
      border-radius: 5px; }
      .answers-detail__content_title h3:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 100%;
        background: #056ab3;
        border-radius: 5px 0 0 5px; }
  .answers-detail__content_pdflist ul li:not(:last-child) {
    margin-bottom: 7px; }
  .answers-detail__content_pdflist ul li a {
    font-family: "Poppins_Regular";
    font-size: 1.5rem;
    line-height: 2rem;
    color: #1a1a18;
    padding: 15px 65px 15px 22px;
    border: 1px solid #dfdfdf;
    position: relative;
    border-radius: 5px;
    position: relative;
    display: block; }
    .answers-detail__content_pdflist ul li a span {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 50px;
      border-left: 1px solid #dfdfdf;
      display: flex;
      align-items: center;
      justify-content: center; }
      .answers-detail__content_pdflist ul li a span i {
        font-size: 19px;
        color: #056ab3; }

.technical-service {
  background: #fff url(../images/content/technical-service-banner.jpg) no-repeat center left;
  background-size: auto 100%; }
  @media (max-width: 991px) {
    .technical-service {
      background-size: auto 560px;
      background-position: top center; } }
  @media (max-width: 767px) {
    .technical-service {
      background-size: auto 400px; } }
  .technical-service section {
    padding: 60px 40px 60px 30px; }
    @media (max-width: 991px) {
      .technical-service section {
        padding-top: 500px;
        padding: 40px; } }
    @media (max-width: 767px) {
      .technical-service section {
        padding-top: 400px; } }
    .technical-service section ul li, .technical-service section p {
      font-family: "Poppins_Light";
      font-size: 1.4rem;
      line-height: 2rem;
      color: #1a1a18;
      margin-bottom: 22px; }
    .technical-service section h2 {
      font-family: "Poppins_Bold";
      font-size: 1.8rem;
      line-height: 2.7rem;
      color: #1f84df;
      margin-bottom: 20px; }
    .technical-service section ul:not(.actions) {
      margin-bottom: 35px; }
      .technical-service section ul:not(.actions) li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 15px; }
        .technical-service section ul:not(.actions) li:before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 7px;
          border-color: transparent transparent transparent #d6d6d6;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%); }
    .technical-service section ul.actions {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-top: 40px; }
      @media (max-width: 991px) {
        .technical-service section ul.actions {
          flex-direction: column;
          align-items: flex-start;
          padding-top: 20px; } }
      .technical-service section ul.actions li:not(:last-child) {
        padding-right: 20px; }
        @media (max-width: 991px) {
          .technical-service section ul.actions li:not(:last-child) {
            padding-right: 0;
            padding-bottom: 20px; } }
      .technical-service section ul.actions li a {
        font-family: "Poppins_Light";
        font-size: 1.3rem;
        line-height: 1.9rem;
        color: #1a1a18;
        background: url(../images/content/button-stroke.png) no-repeat center left;
        background-size: auto 100%;
        padding: 14px;
        white-space: nowrap; }
        .technical-service section ul.actions li a i {
          font-size: 23px;
          color: #1f84df;
          margin-right: 10px; }

.contact__content._row .row > div.col-lg-6:first-child {
  border-right: 1px solid #e5e5e5; }
  @media (max-width: 991px) {
    .contact__content._row .row > div.col-lg-6:first-child {
      border-right: none; } }

.contact__content._branches_list .row > div.col-lg-6 {
  margin-bottom: 30px; }

.contact__content #map {
  height: 200px;
  width: 100%;
  margin-bottom: 55px;
  border: 1px solid #ddd; }
  @media (max-width: 991px) {
    .contact__content #map {
      margin-bottom: 30px; } }

.contact__content h3 {
  font-family: "Poppins_Semibold";
  font-size: 3.5rem;
  line-height: 4.2rem;
  color: #f1f1f1;
  margin-bottom: 20px; }
  @media (max-width: 1199px) {
    .contact__content h3 {
      font-size: 2.8rem;
      line-height: 3.6rem; } }
  .contact__content h3 span {
    font-family: "Poppins_Light"; }

.contact__content_information address {
  font-family: "Poppins_Regular";
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #505050;
  margin-bottom: 20px; }

.contact__content_information ul li:not(:last-child) {
  margin-bottom: 15px; }

.contact__content_information ul li a {
  font-family: "Poppins_Regular";
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #505050; }
  .contact__content_information ul li a i {
    font-size: 22px;
    color: #056ab3;
    margin-right: 9px; }

.contact__content_form {
  padding: 0 40px; }
  @media (max-width: 1199px) {
    .contact__content_form {
      padding: 0 25px; } }
  @media (max-width: 991px) {
    .contact__content_form {
      padding: 40px 0 0 0; } }
  .contact__content_form_wrap {
    margin-bottom: 17px; }
    .contact__content_form_wrap.button {
      padding-top: 15px; }
  .contact__content_form input, .contact__content_form textarea {
    font-family: "Poppins_Regular";
    font-size: 1.3rem;
    line-height: 1.7rem;
    color: #545454;
    border-bottom: 1px solid #d8d8d8;
    padding: 7px 0;
    width: 100%;
    resize: none;
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out; }
    .contact__content_form input:focus, .contact__content_form textarea:focus {
      border-bottom-color: #056ab3; }
      .contact__content_form input:focus::-webkit-input-placeholder, .contact__content_form textarea:focus::-webkit-input-placeholder {
        color: #056ab3; }
      .contact__content_form input:focus:-moz-placeholder, .contact__content_form textarea:focus:-moz-placeholder {
        color: #056ab3; }
      .contact__content_form input:focus::-moz-placeholder, .contact__content_form textarea:focus::-moz-placeholder {
        color: #056ab3; }
      .contact__content_form input:focus:-ms-input-placeholder, .contact__content_form textarea:focus:-ms-input-placeholder {
        color: #056ab3; }
  .contact__content_form button {
    padding: 12px 15px;
    font-family: "Poppins_Regular";
    font-size: 1.4rem;
    line-height: 2rem;
    color: #fff;
    background: #056ab3;
    border-radius: 3px;
    width: 100%; }
    .contact__content_form button i {
      font-size: 22px;
      margin-right: 7px; }
  .contact__content_form label.error {
    font-family: "Poppins_Light";
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: red;
    margin-top: 5px; }

.contact__content_branches {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  height: 100%;
  padding-bottom: 46px;
  position: relative; }
  @media (max-width: 576px) {
    .contact__content_branches {
      padding-bottom: 71px; } }
  .contact__content_branches section {
    padding: 22px 20px; }
    .contact__content_branches section h4 {
      font-family: "Poppins_Regular";
      font-size: 1.5rem;
      line-height: 2rem;
      color: #1a1a18;
      margin-bottom: 17px; }
      .contact__content_branches section h4 span {
        font-family: "Poppins_Semibold"; }
    .contact__content_branches section p {
      font-family: "Poppins_Regular";
      font-size: 1.4rem;
      line-height: 2rem;
      color: #1a1a18; }
  .contact__content_branches ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #dcdcdc;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; }
    .contact__content_branches ul li {
      width: 50%; }
      .contact__content_branches ul li:first-child {
        border-right: 1px solid #dcdcdc; }
      .contact__content_branches ul li a {
        display: block;
        font-family: "Poppins_Regular";
        font-size: 1.4rem;
        line-height: 2rem;
        color: #1a1a18;
        text-align: center;
        padding: 12px 5px; }
        .contact__content_branches ul li a i {
          font-size: 22px;
          margin-right: 6px;
          color: #056ab3; }
          @media (max-width: 576px) {
            .contact__content_branches ul li a i {
              display: block;
              margin-right: 0;
              margin-bottom: 7px; } }

.alert {
  font-family: "Poppins_Regular";
  font-size: 1.4rem;
  line-height: 1.8rem; }

.offer-list {
  padding: 60px;
  display: inline-block;
  width: 100%; }
  @media (max-width: 991px) {
    .offer-list {
      padding: 30px 20px; } }
  .offer-list table tr th, .offer-list table tr td {
    text-align: center;
    font-family: "Poppins_Regular";
    font-size: 1.5rem;
    line-height: 2rem;
    color: #1a1a18;
    vertical-align: middle; }
    .offer-list table tr th img, .offer-list table tr td img {
      max-height: 100px; }
    .offer-list table tr th a.product-delete i, .offer-list table tr td a.product-delete i {
      font-size: 20px;
      color: #1a1a18;
      transform: rotate(45deg); }
  .offer-list table tr th {
    font-family: "Poppins_Medium";
    color: #056ab3; }
    @media (max-width: 991px) {
      .offer-list table tr th.xs-hidden {
        display: none; } }
    .offer-list table tr th.xs-visible {
      display: none; }
      @media (max-width: 991px) {
        .offer-list table tr th.xs-visible {
          display: block; } }
  @media (max-width: 991px) {
    .offer-list table tr td {
      display: block; } }
  .offer-list table tr:nth-child(2n+1) td {
    background: #f9f9f9; }
  .offer-list a.offer-confirm {
    font-family: "Poppins_Regular";
    font-size: 1.5rem;
    line-height: 2rem;
    color: #fff;
    background: #056ab3;
    padding: 10px 25px;
    border-radius: 5px;
    display: inline-block;
    margin: 15px 0 20px 0;
    float: right; }

.page-path {
  margin-bottom: 30px; }
  .page-path ul {
    display: inline-block; }
    .page-path ul li {
      position: relative;
      float: left;
      padding: 2px 15px 2px 0px; }
      .page-path ul li:not(:last-child):after {
        content: ">";
        font-family: "Poppins_Regular";
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #1a1a18;
        position: absolute;
        right: 4px; }
      .page-path ul li a {
        font-family: "Poppins_Regular";
        font-size: 1.4rem;
        line-height: 2rem;
        color: #1a1a18; }
      .page-path ul li.active a {
        color: #056ab3; }

.products_list .row > div {
  margin-bottom: 30px; }

.products_list__banner {
  margin-bottom: 20px; }
  .products_list__banner figure {
    height: 250px;
    width: 100%; }

.products_list__categorie_content h1 {
  font-family: "Poppins_Medium";
  font-size: 2rem;
  line-height: 2.6rem;
  color: #1a1a18;
  margin-bottom: 14px; }

.products_list__categorie_content p {
  font-family: "Poppins_Light";
  font-size: 1.4rem;
  line-height: 2rem;
  color: #1a1a18;
  margin-bottom: 25px; }

.products_list__wrap {
  height: 100%; }
  .products_list__wrap a {
    display: block;
    padding: 25px 20px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    height: 100%;
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out; }
    .products_list__wrap a:hover {
      border-color: #1f84df; }
    .products_list__wrap a figure {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px; }
      .products_list__wrap a figure img {
        max-width: 180px;
        max-height: 180px; }
    .products_list__wrap a h3 {
      font-family: "Poppins_Medium";
      font-size: 1.6rem;
      line-height: 2rem;
      color: #1a1a18;
      text-align: center; }

body {
  background: url(../images/content/body-bg.jpg) repeat-x top center;
  background-size: cover; }
