.page-path{
	margin-bottom: 30px;
	ul{
		display: inline-block;
		li{
			position: relative;
			float: left;
			padding: 2px 15px 2px 0px;
			&:not(:last-child){
				&:after{
					content:">";
					font-family: $poppinsregular;
					@include font-size(16,22);
					color:$lightblack;
					position: absolute;
					right: 4px;
				}
			}
			a{
				font-family: $poppinsregular;
				@include font-size(14,20);
				color:$lightblack;
			}
			&.active{
				a{
					color:$darkblue;
				}
			}
		}
	}
}