.project_detail{
	.carousel{
		height: 100%;
		.carousel-inner{
			height: 100%;
			.carousel-item{
				height: 100%;
			}
		}
	}
	ol{
		bottom: 30px;
		@media (max-width: $md-width){
			bottom: 15px;
		}
		li{
			background-color: $white;
			opacity: 1;
			&.active{
				background-color: $blue;
			}
		}
	}
	figure{
		min-height: 700px;
		height: 100%;
		width: 100%;
		position: relative;
		&:before{
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $darkblue;
			opacity: 0.3;
		}
		@media (max-width: $md-width){
			min-height: 500px;
		}
		@media (max-width: $sm-width){
			min-height: 400px;
		}
	}
	section{
		padding: 60px 60px 60px 30px;
		@media (max-width: $md-width){
			padding: 30px;
		}
		p{
			font-family: $poppinslight;
			@include font-size(15,20);
			color:$lightblack;
			i{
				font-size: 20px;
				color:$darkblue;
				margin-right: 7px;
			}
		}
		h2,h3{
			font-family: $poppinsbold;
			@include font-size(20,28);
			color:$lightblack;
			margin:24px 0;
		}
		h3{
			margin-top: 35px;
		}
		table{
			tr{
				td{
					padding: 12px 10px;
					font-family: $poppinslight;
					@include font-size(12,14);
					color:$lightblack;
					border-right: none;
					border-left: none;
					vertical-align: middle;
				}
			}
		}
	}
}