.main-projects{
	&__text{
		background: $white;
		height: 236px;
		position: relative;
		&:before{
			content:"";
			left: 100%;
			width: 30px;
			height: 100%;
			background: $white;
			display: inline-block;
			top: 0;
			position: absolute;
			@media (max-width: $md-width){
				content:none;
			}
		}
		&_title{
			padding: 24px 15px 20px 40px;
			position: relative;
			@media (max-width: $sm-width){
				padding-left: 24px;
			}
			&:before{
				content:"";
				position: absolute;
				left: 0;
				bottom: 0;
				height: 1px;
				width: 50%;
				background: rgb(232,232,232);
				background: linear-gradient(90deg, rgba(232,232,232,1) 47%, rgba(255,255,255,1) 100%);
			}
			&:after{
				content:"";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 30%;
				height: 1px;
				background: $darkblue;
			}
			h3{
				font-family: $proximanovabold;
				@include font-size(18,27);
				color:$lightblack;
				i{
					margin-right: 10px;
					font-size: 24px;
					vertical-align: text-bottom;
				}
			}
		}
		section{
			padding: 23px 0 28px 40px;
			position: relative;
			@media (max-width: $sm-width){
				padding-left: 24px;
			}
			p{
				font-family: $poppinsregular;
				@include font-size(14,20);
				color:$lightblack;
				display: -webkit-box;
			    -webkit-box-orient: vertical;
			    -webkit-line-clamp: 3;
			    overflow: hidden;
			}
			.swiper-control{
			    position: absolute;
			    max-width: 66px;
			    width: 100%;
			    height: 33px;
			    bottom: -25px;
			    left: 40px;
			    @media (max-width: $sm-width){
					left: 24px;
				}
			    &__button{
			    	background: $darkblue;
			    	width: 50%;
			    	opacity: 1;
			    	outline: none;
			    	height: 33px;
			    	margin:0;
			    	top: auto;
			    	&:last-child{
			    		background:$white;
			    		border:1px solid $darkblue;
			    	}
					&:before{
						font-family: 'icomoon';
						content:"\e90d";
						font-size: 16px;
						color:$darkblue;
					}
					&:after{
						content:none;
					}
					&.swiper-button-prev{
						left: 0;
						right: auto;
						&:before{
							transform:rotate(180deg);
							color:$white;
						}
					}
					&.swiper-button-next{
						right: 0;
						left: auto;
					}
			    }
			}
		}
	}
	.swiper-container{
		.swiper-slide{
			&:nth-child(3n+1){
				&:before{
					content:"";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(5,106,179,0.7);
				}
			}
			&:nth-child(3n+2){
				figure{
					filter: grayscale(100%);
				}
			}
			&:nth-child(3n+0){
				&:before{
					content:"";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(40,40,40,0.7);
				}
				figure{
					filter: grayscale(80%);
				}
			}
			.main-projects__card-information{
				position: absolute;
				bottom: 0;
				padding:15px 25px 23px 25px;
				span{
					display: block;
					font-family: $poppinsregular;
					@include font-size(15,20);
					color:$white;
					position: relative;
					&:before{
						content:"";
						position: absolute;
						background: $white;
						height: 1px;
					    left: 50px;
					    right: 0;
					    top: 50%;
					    transform: translate(0,-50%);
					}
				}
				p{
					font-family: $poppinslight;
					@include font-size(12,16);
					color:$white;
					margin-top: 16px;
					display: -webkit-box;
				    -webkit-box-orient: vertical;
				    -webkit-line-clamp: 2;
				    overflow: hidden;
				}
			}
			span{
				&.plus{
					display: inline-block;
					border:1px solid $white;
					width: 32px;
					height: 32px;
					position: absolute;
					top: 24px;
					left: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 2;
					i{
						font-size: 16px;
						color:$white;
					}
				}
			}
		}
		figure{
			height: 236px;
			width: 100%;
		}
	}
}