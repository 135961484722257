header{
	padding: 24px 0;
	background: $white url(#{$images}/content/header-footer-bg.jpg) no-repeat center;
	background-size: cover;
	@media (max-width: $md-width){
		padding: 10px 0;
		background-image: none;
		position: fixed !important;
		top: 0;
		width: 100%;
		z-index: 999;
		min-width: 320px;
	}
	&.sub-header{
		background: none;
		position: relative;
		margin-bottom: 90px;
		@media (max-width: $md-width){
			background: $lightblack;
		}
		&:before{
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 336px;
			background: url(#{$images}/content/subpage-banner.jpg) no-repeat center;
			background-size: cover;
			z-index: 0;
			@media (max-width: $md-width){
				display: none;
			}
		}
		nav{
			.mobile-speed-nav{
				li{
					a{
						&.offer-button{
							background: #111111;
						}
					}
				}
				button{
					&.navbar-toggler{
						span.navbar-toggler-icon{
							background: $white;
							&:before,&:after{
								background:$white;
							}
						}
					}
				}
			}
			ul{
				li{
					@media (max-width: $md-width){
						border-top-color: $black;
					}
					a{
						color:$white;
					}
				}
				&.nav-right{
					li{
						a{
							&.offer-button{
								background: #111111;
							}
						}
					}
				}
			}
		}
	}
	nav{
		margin:0;
		padding: 0 !important;
		align-items: flex-start !important;
		.navbar-brand{
			img{
				max-width: 230px;
				width: 100%;
				@media (max-width: $xs-width){
					max-width: 190px;
				}
				@media (max-width: $mobile-width){
					max-width: 140px;
				}
			}
		}
		.navbar-collapse{
			justify-content: space-around;
		}
		.mobile-speed-nav{
			padding: 0;
			display: none;
			@media (max-width: $md-width){
				display: flex;
				align-items: center;
				margin-top: 8px;
			}
			@media (max-width: $xs-width){
				margin-top:5px;
			}
			@media (max-width: $mobile-width){
				margin-top:0;
			}
			li{
				padding: 0 5px;
				border:none;
				a{
					font-family: $poppinsmedium;
					font-size: 14px;
					display: inline-block;
					padding: 9px 7px;
					color:$white;
					width: 37px;
					height: 37px;
					text-align: center;
					i{
						font-size: 20px;
						color:$white;
					}
					&.offer-button{
						background: $lightblack;
					}
					&.lang-button{
						background: $blue;
					}
				}
			}
		}
		button{
			&.navbar-toggler{
				padding: 0;
				margin:0;
				height: 32px;
				width: 35px;
				span.navbar-toggler-icon{
					width: 32px;
				    height: 2.5px;
				    background: $lightblack;
				    position: relative;
				    &:before,&:after{
				    	content:"";
				    	width: 32px;
					    height: 2.5px;
					    background: $lightblack;
					    position: absolute;
					    display: inline-block;
					    left: 0;
				    }
				    &:before{
				    	top: -12px;
				    }
				    &:after{
				    	bottom: -12px;
				    }
				}
			}
		}
		ul{
			display: flex;
			padding-top: 4px;
			li{
				padding:0 15px;
				&:last-child{
					padding-right:0;
				}
				@media (max-width: $lg-width){
					padding: 0 7px;
				}
				@media (max-width: $md-width){
					padding:8px 0;
					border-top:1px solid #f3f3f3;
				}
				a{
					font-family: $poppinsregular;
					@include font-size(13,17);
					color:$lightblack;
					@include transition;
					&:hover{
						color:$blue;
					}
					&.active{
						color:$darkblue;
					}
				}
			}
			&.navbar-nav{
				margin:0 auto;
			}
			&.nav-right{
				@media (max-width: $md-width){
					display: none;
				}
				li{
					padding: 0;
					&:not(:last-child){
						padding-right: 5px;
					}
					a{
						@include font-size(14,21);
						font-family: $poppinsmedium;
						color:$white;
						padding: 13px 16px;
						display: inline-block;
						text-align: center;
						&.offer-button{
							background: $lightblack;
							min-width: 150px;
							@media (max-width: $lg-width){
								min-width: auto;
							}
						}
						&.lang-button{
							background: $blue;
						}
						i{
							margin-right: 7px;
							font-size: 20px;
							color:$white;
						}
					}
				}
			}
		}
	}
}

a.offer-button{
	position: relative;
	span{
		position: absolute;
		left: -7px;
		top: -7px;
		width: 22px;
		height: 22px;
		background:$blue;
		border-radius: 50%;
		@include font-size(12,14);
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.swal2-popup{
	font-size: 150% !important;
	max-width: 500px;
	width: 100%;
	.swal2-title{
		font-family: $poppinsmedium;
		@include font-size(18,24);
		color:$lightblack;
	}
	.swal2-html-container{
		font-family: $poppinsregular;
		@include font-size(15,20);
		color: $lightblack;
	}
	.swal2-confirm{
		font-family: $poppinsmedium;
		@include font-size(15,20);
		color:$white;
		background: $darkblue;
		outline: none;
		box-shadow: 0 0 0 0;
	}
}

body{
	@media (max-width: $md-width){
		padding-top: 73.47px;
	}
	@media (max-width: $xs-width){
		padding-top: 68.41px;
	}
	@media (max-width: $mobile-width){
		padding-top: 57px;
	}
}