.main-slider{
	position: relative;
	&:before{
		content:"";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.5);
		z-index: 1;
	}
	.carousel-indicators{
		display: none;
		@media (max-width: $lg-width){
			display: flex;
		}
		li{
			background-color: $blue;
		}
	}
	figure{
		height: 569px;
		width: 100%;
		@media (max-width: $md-width){
			height: 400px;
		}
	}
	&__title{
		position: absolute;
		left: 0;
		width: 100%;
		top: 0;
		z-index: 2;
		height: 100%;
		display: flex;
		align-items: center;
		h1{
			font-family: $poppinssemibold;
			@include font-size(37,46);
			color:$white;
			margin-bottom: 15px;
			@media (max-width: $md-width){
				@include font-size(28,36);
			}
			span{
				display: block;
			}
		}
		p{
			font-family: $poppinslight;
			@include font-size(13,18);
			color:$white;
		}
	}
	a{
		&.carousel-control{
			width: 53px;
			height: 53px;
			background: $blue;
			opacity: 1;
			top: 50%;
			z-index: 3;
			transform: translate(0,-50%);
			@media (max-width: $lg-width){
				display: none;
			}
			&.carousel-control-prev{
				&:before{
					transform:rotate(180deg);
				}
			}
			&.carousel-control-prev,&.carousel-control-next{
				&:before{
					font-family: 'icomoon';
					content:"\e90d";
					font-size: 25px;
					color:$white;
				}
			}
		}
	}
	.carousel-item{
		h1 span,p,a{
			animation-duration: var(--animate-duration);
			animation-fill-mode: both;
		}
		h1{
			span{
				opacity: 0;
				&:before{
					animation-duration: var(--animate-duration);
					animation-fill-mode: both;
				}
			}
		}
		p{
			opacity: 0;
		}
	}
	.carousel-item{
		&.active{
			h1{
				span{
					animation-name: fadeInLeft;
					&:nth-child(1){
						animation-delay: 0.4s;
					}
					&:nth-child(2){
						animation-delay: 0.7s;
					}
				}
			}
			p{
				animation-name: fadeInUp;
				animation-delay: 1s;
			}
		}
	}
}