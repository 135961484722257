.answers{
	padding: 70px 64px;
	@media (max-width: $md-width){
		padding: 40px 30px;
	}
	@media (max-width: $mobile-width){
		padding: 40px 15px;
	}
	&__wrap{
		position: relative;
		border:1px solid #dfdfdf;
		border-radius: 5px;
		margin-bottom: 30px;
		overflow: hidden;
		&:before,&:after{
			content:"";
			position: absolute;
			background: #c6c6c6;
			@include transition;
			z-index: 1;
		}
		&:before{
			right: 10px;
			bottom: 10px;
			height: 80%;
			width: 1px;
		}
		&:after{
			right: 10px;
			bottom: 10px;
			height: 1px;
			width: 40%;
		}
		figure{
			width: 100%;
			height: 210px;
			position: relative;
			background-position: left center !important;
    		background-size: auto 100% !important;
			&:before{
				content:"";
				position: absolute;
				right: 0;
				width: 100%;
				top: 0;
				height: 100%;
				background: url(#{$images}/content/answers/answers-shadow.png) no-repeat center;
				background-position: center right -110px;
				@media (max-width: $lg-width){
					background-position: center right -180px;
				}
				@media (max-width: $sm-width){
					background-position: center right -130px;
				}
				@media (max-width: $xs-width){
					background-position: center right -180px;
				}
			}
		}
		> span{
			display: inline-block;
			width: 47px;
			height: 47px;
			background: #c6c6c6;
			position: absolute;
			right: 40px;
			top: 30px;
			border-radius: 3px;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1;
			@include transition;
			i{
				font-size: 22px;
				color:$white;
			}
		}
		&_title{
			position: absolute;
			right: 40px;
			bottom: 40px;
			text-align: right;
			h3{
				font-family: $poppinslight;
				@include font-size(19,27);
				color:$lightblack;
				span{
					font-family: $poppinssemibold;
					display: block;
				}
			}
		}
		&:hover{
			&:before,&:after{
				background:$darkblue;
			}
			> span{
				background:$darkblue;
			}
		}
	}
}