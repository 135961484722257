.offer-list{
	padding: 60px;
	display: inline-block;
	width: 100%;
	@media (max-width: $md-width){
		padding: 30px 20px;
	}
	table{
		tr{
			th,td{
				text-align: center;
				font-family: $poppinsregular;
				@include font-size(15,20);
				color:$lightblack;
				vertical-align: middle;
				img{
					max-height: 100px;
				}
				a{
					&.product-delete{
						i{
							font-size: 20px;
							color:$lightblack;
							transform: rotate(45deg);
						}
					}
				}
			}
			th{
				font-family: $poppinsmedium;
				color:$darkblue;
				&.xs-hidden{
					@media (max-width: $md-width){
						display: none;
					}
				}
				&.xs-visible{
					display: none;
					@media (max-width: $md-width){
						display: block;
					}
				}
			}
			td{
				@media (max-width: $md-width){
					display: block;
				}
			}
			&:nth-child(2n+1){
				td{
					background:#f9f9f9;
				}
			}
		}
	}
	a{
		&.offer-confirm{
			font-family: $poppinsregular;
			@include font-size(15,20);
			color:$white;
			background: $darkblue;
			padding: 10px 25px;
			border-radius: 5px;
			display: inline-block;
			margin:15px 0 20px 0;
			float: right;
		}
	}
}