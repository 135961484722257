@font-face {
    font-family: 'Poppins_Bold';
    src: url('#{$fonts}/Poppins-Bold.eot');
    src: url('#{$fonts}/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-Bold.woff2') format('woff2'),
         url('#{$fonts}/Poppins-Bold.woff') format('woff'),
         url('#{$fonts}/Poppins-Bold.ttf')  format('truetype'),
         url('#{$fonts}/Poppins-Bold.svg#Poppins') format('svg');
}
@font-face {
    font-family: 'Poppins_Light';
    src: url('#{$fonts}/Poppins-Light.eot');
    src: url('#{$fonts}/Poppins-Light.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-Light.woff2') format('woff2'),
         url('#{$fonts}/Poppins-Light.woff') format('woff'),
         url('#{$fonts}/Poppins-Light.ttf')  format('truetype'),
         url('#{$fonts}/Poppins-Light.svg#Poppins Light') format('svg');
}
@font-face {
    font-family: 'Poppins_Medium';
    src: url('#{$fonts}/Poppins-Medium.eot');
    src: url('#{$fonts}/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-Medium.woff2') format('woff2'),
         url('#{$fonts}/Poppins-Medium.woff') format('woff'),
         url('#{$fonts}/Poppins-Medium.ttf')  format('truetype'),
         url('#{$fonts}/Poppins-Medium.svg#Poppins Medium') format('svg');
}
@font-face {
    font-family: 'Poppins_Regular';
    src: url('#{$fonts}/Poppins-Regular.eot');
    src: url('#{$fonts}/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-Regular.woff2') format('woff2'),
         url('#{$fonts}/Poppins-Regular.woff') format('woff'),
         url('#{$fonts}/Poppins-Regular.ttf')  format('truetype'),
         url('#{$fonts}/Poppins-Regular.svg#Poppins') format('svg');
}
@font-face {
    font-family: 'Poppins_SemiBold';
    src: url('#{$fonts}/Poppins-SemiBold.eot');
    src: url('#{$fonts}/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-SemiBold.woff2') format('woff2'),
         url('#{$fonts}/Poppins-SemiBold.woff') format('woff'),
         url('#{$fonts}/Poppins-SemiBold.ttf')  format('truetype'),
         url('#{$fonts}/Poppins-SemiBold.svg#Poppins SemiBold') format('svg');
}
@font-face {
    font-family: 'Proxima_Nova_Bold';
    src: url('#{$fonts}/ProximaNova-Bold.eot');
    src: url('#{$fonts}/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/ProximaNova-Bold.woff2') format('woff2'),
         url('#{$fonts}/ProximaNova-Bold.woff') format('woff'),
         url('#{$fonts}/ProximaNova-Bold.ttf')  format('truetype'),
         url('#{$fonts}/ProximaNova-Bold.svg#Proxima Nova') format('svg');
}
    @font-face {
    font-family: 'icomoon';
    src:  url('#{$fonts}/icomoon.eot?9bnil7');
    src:  url('#{$fonts}/icomoon.eot?9bnil7#iefix') format('embedded-opentype'),
        url('#{$fonts}/icomoon.ttf?9bnil7') format('truetype'),
        url('#{$fonts}/icomoon.woff?9bnil7') format('woff'),
        url('#{$fonts}/icomoon.svg?9bnil7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
    }