//......................................
//= PATHS
//......................................

$images 				:	'../images';
$fonts					:	'../fonts';

$poppinsbold			:	'Poppins_Bold';
$poppinsmedium			:	'Poppins_Medium';
$poppinssemibold		:	'Poppins_Semibold';
$poppinsregular			:	'Poppins_Regular';
$poppinslight			:	'Poppins_Light';
$proximanovabold		:	'Proxima_Nova_Bold';

//COLORS		
$black					:	#000;
$lightblack				:	#1a1a18;
$white					:	#fff;
$blue					:	#1f84df;
$darkblue				:	#056ab3;
$lightgrey				:	#e8e8e8;
$butongrey				:	#f5f5f5;

//BREAKPOINTS
$lg-width				: 1199px;
$md-width				: 991px;
$sm-width				: 767px;
$xs-width				: 576px;

$mobile-width			: 375px;