.corporate{
	&__content{
		p{
			font-family: $poppinslight;
			@include font-size(14,20);
			color:$lightblack;
		}
		&_banner{
			margin-bottom: 20px;
			figure{
				height: 250px;
				width: 100%;
			}
		}
		&_partners{
			figure{
				background: #f9f9f9;
				padding: 26px;
				height: 127px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 5px;
				margin-bottom: 30px;
				img{
					max-width: 100%;
					max-height: 44px;
					filter: grayscale(100%);
					@include transition;
				}
				&:hover{
					img{
						filter: grayscale(0);
					}
				}
			}
		}
		&_references{
			.row{
				> div{
					margin-bottom: 30px;
				}
			}
			&_wrap{
				padding: 40px 22px 22px 22px;
				border:1px solid #e8e8e8;
				border-radius: 5px;
				position: relative;
				height: 100%;
				&:before{
					content:"\e906";
					font-family: 'icomoon';
					font-size: 23px;
					color:#ababab;
					position: absolute;
					right: 15px;
					top: 15px;
					@include transition;
					visibility: hidden;
					opacity: 0;
				}
				figure{
					height: 115px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 36px;
					img{
						max-height: 115px;
						max-width: 100%;
					}
				}
				h4{
					font-family: $poppinssemibold;
					@include font-size(13,20);
					color:$lightblack;
					text-align: center;
				}
				&.photo-active{
					&:before{
						opacity:1;
						visibility: visible;
					}	
				}
				&:hover{
					&:before{
						color:$darkblue;
					}
				}
			}
		}
		&_certificates{
			.row{
				> div{
					margin-bottom: 30px;
				}
			}
			&_wrap{
				border:1px solid #e8e8e8;
				border-radius: 5px;
				height: 100%;
				figure{
					height: 198px;
					border-bottom: 1px solid #e8e8e8;
					padding: 22px;
					display: flex;
					align-items: center;
					justify-content: center;
					img{
						max-height: 160px;
						max-width: 100%;
					}
				}
				h4{
					font-family: $poppinsregular;
					@include font-size(12,16);
					color:$lightblack;
					padding: 14px 5px;
					text-align: center;
					@include transition;
				}
				&:hover{
					h4{
						color:$darkblue;
					}
				}
			}
		}
		&_cv{
			margin-top: 40px;
			&_title{
				margin-bottom: 40px;
				h3{
					font-family: $poppinsregular;
					@include font-size(20,28);
					color:$darkblue;
					position: relative;
					&:before{
						content:"";
						position: absolute;
						left: 150px;
						background: #e1e1e1;
						height: 1px;
						right: 0;
						top: 50%;
						margin-top: -0.5px;
					}
					&:after{
						content:"";
						position: absolute;
						left: 150px;
						top: 50%;
						margin-top: -0.5px;
						height: 1px;
						width: 20%;
						background:$darkblue;
					}
					span{
						font-family: $poppinssemibold;
					}
				}
			}
			&_form{
				&_wrap{
					margin-bottom: 20px;
					&.button{
						justify-content: flex-end;
						@media (max-width: $md-width){
							justify-content: flex-start;
							align-items: flex-start;
						}
					}
					input[type="text"]{
						border:1px solid #e1e1e1;
						padding: 12px 15px;
						font-family: $poppinsregular;
						@include font-size(15,20);
						color:$lightblack;
						width: 100%;
					}
					input[type="file"]{
						display: none;
					}
					label{
						&:not(.error){
							border:1px solid #e1e1e1;
							padding: 12px 15px;
							width: 100%;
							position: relative;
							height: 46px;
							cursor: pointer;
							font-family: $poppinslight;
							@include font-size(15,20);
							color:$lightblack;
							@media (max-width: $md-width){
								width: 100%;
							}
							span{
								&.iconed{
									display: inline-block;
									font-family: $poppinslight;
									@include font-size(11,15);
									color:$lightblack;
									border-left: 1px solid #e1e1e1;
									height: 100%;
									position: absolute;
									right: 0;
									top: 0;
									padding: 15px;
									i{
										font-size: 15px;
										color:$darkblue;
										margin-right: 6px;
									}
								}
								&.file-name{
									display: inline-block;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									width: 50%;
								}
							}
						}
						&.error{
							font-family: $poppinslight;
							@include font-size(11,15);
							color:red;
							margin-top: 5px;
							display: block;
						}
					}
					button{
						padding: 12px 15px;
						font-family: $poppinsregular;
						@include font-size(14,20);
						color:$white;
						background: $darkblue;
						border-radius: 3px;
						min-width: 180px;
						i{
							font-size: 22px;
							margin-right: 7px;
						}
					}
				}
			}
		}
	}
}