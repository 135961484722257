.main-hero{
	margin:38px 0 35px 0;
	@media (max-width: $md-width){
		margin:15px 0;
	}
	&__plus{
		display: inline-block;
		width: 46px;
		height: 46px;
		background: $darkblue;
		text-align: center;
		position: absolute;
		right: 24px;
		top: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 5;
		i{
			font-size: 14px;
			color:$white;
		}
	}
	&__title{
		padding: 24px 15px 20px 40px;
		position: relative;
		@media (max-width: $sm-width){
			padding-left: 24px;
		}
		&:before{
			content:"";
			position: absolute;
			left: 0;
			bottom: 0;
			height: 1px;
			width: 50%;
			background: rgb(232,232,232);
			background: linear-gradient(90deg, rgba(232,232,232,1) 47%, rgba(255,255,255,1) 100%);
		}
		&:after{
			content:"";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 30%;
			height: 1px;
			background: $darkblue;
		}
		h3{
			font-family: $proximanovabold;
			@include font-size(18,27);
			color:$lightblack;
			i{
				margin-right: 10px;
				font-size: 24px;
				vertical-align: text-bottom;
			}
		}
	}
	section{
		padding: 25px 15px 37px 40px;
		@media (max-width: $sm-width){
			padding-left: 24px;
		}
		h5{
			font-family: $proximanovabold;
			@include font-size(16,20);
			color:$lightblack;
			margin-bottom: 16px;
			display: -webkit-box;
		    -webkit-box-orient: vertical;
		    -webkit-line-clamp: 3;
		    overflow: hidden;
		    span{
		    	display: block;
		    }
		}
		p{
			font-family: $poppinsregular;
			@include font-size(14,20);
			color:$lightblack;
			display: -webkit-box;
		    -webkit-box-orient: vertical;
		    -webkit-line-clamp: 4;
		    overflow: hidden;
		}
	}
	&__corporate,&__answers{
		&:before,&:after{
			content:"";
			position: absolute;
			z-index: 5;
			background: $white;
		}
		&:before{
			bottom: 24px;
			width: 1px;
			height: 60%;
			right: 24px;
		}
		&:after{
			bottom: 24px;
			width: 40%;
			height: 1px;
			right: 24px;
		}
	}
	&__corporate{
		background: $white url(#{$images}/content/main-corporate.jpg) no-repeat center right;
		padding-right: 280px;
		background-size: cover;
		position: relative;
		height: 280px;
		@media (max-width: $md-width){
			padding-right: 50px;
			margin-bottom: 15px;
		}
	}
	&__answers{
		background: $white;
		position: relative;
		height: 280px;
		.carousel{
			height: 100%;
			.carousel-inner{
				height: 100%;
			}
		}
		.main-hero__title{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
		}
		section{
			padding-top: 96px;
			padding-right: 230px;
			@media (max-width: $lg-width){
				padding-right: 160px;
			}
			@media (max-width: $md-width){
				padding-right: 240px;
			}
			@media (max-width: $xs-width){
				padding-right: 140px;
			}
			h5{
				display: block;
				@include font-size(17,21);
				@media (max-width: $mobile-width){
					@include font-size(14,18);
				}
			}
			p{
				-webkit-line-clamp:3;
			}
		}
		figure{
			max-width: 218px;
			width: 100%;
			height: 280px;
			width: 100%;
			position: absolute;
			right: 0;
			top: 0;
			@media (max-width: $lg-width){
				max-width: 148px;
			}
			@media (max-width: $md-width){
				max-width: 220px;
			}
			@media (max-width: $xs-width){
				max-width: 128px;
			}
		}
		.answer-slide__control{
		    position: absolute;
		    max-width: 66px;
		    width: 100%;
		    height: 33px;
		    bottom: 20px;
		    left: 40px;
		    @media (max-width: $sm-width){
				left: 24px;
			}
		    a{
		    	background: $darkblue;
		    	width: 50%;
		    	opacity: 1;
		    	&:last-child{
		    		background:$white;
		    		border:1px solid $darkblue;
		    	}
		    	&.carousel-control-prev,&.carousel-control-next{
					&:before{
						font-family: 'icomoon';
						content:"\e90d";
						font-size: 16px;
						color:$darkblue;
					}
				}
				&.carousel-control-prev{
					&:before{
						transform:rotate(180deg);
						color:$white;
					}
				}
		    }
		}
		.carousel-item{
			h5,p{
				animation-duration: var(--animate-duration);
				animation-fill-mode: both;
				opacity: 0;
			}
			&.active{
				h5{
					animation-name: fadeInDown;
				}
				p{
					animation-name: fadeInLeft;
					animation-delay: 0.4s;
				}
			}
		}
	}
}